import classNames from "classnames";
import { ClipboardCopy, ProductImage } from "components";
import renderPrice from "utils/renderPrice";

const OrderLine = ({
  id,
  quantity,
  unit_price,
  product_sku,
  product_name,
  product,
  currency_code,
  has_error,
}) => {
  const sku = product_sku || product?.sku || "-";
  const name = product?.name || product_name || "-";
  const tooltip_id = [id, sku, "image"].join("_");

  return (
    <div className="order-line">
      <div className="box-image">
        <ProductImage
          image={product?.image_url}
          thumb={product?.image_thumb_url}
          alt={name}
          id={tooltip_id}
          quantity={quantity}
        />
      </div>
      <div className="box-text">
        <p
          className={classNames({
            "text-danger": has_error,
          })}>
          <span className="box-text__qty">
            {quantity}x{" "}
          </span>
          {name}
        </p>
        <p className="box-text__codes">
          {product?.ean && (
            <span>
              EAN:{" "}
              <ClipboardCopy
                id={`${id}_${product?.ean}`}
                text={product?.ean}
              />
            </span>
          )}
          {sku && (
            <span>
              SKU:{" "}
              <ClipboardCopy
                id={`${id}_${sku}`}
                text={sku}
              />
            </span>
          )}
          {unit_price && (
            <span>
              {renderPrice(unit_price, currency_code)}
            </span>
          )}
        </p>
      </div>
    </div>
  );
};

export default OrderLine;
