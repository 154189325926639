import Header from "./components/Header";
import Table from "./components/Table";
import Pagination from "./components/Pagination";
import BottomAction from "./components/BottomAction";
import MainFilters from "./components/MainFilters";

import MainListProvider from "./context/provider";

const MainList = (props) => (
  <MainListProvider props={props}>
    <div className="main-list">
      <Header />
      <MainFilters />
      <Table />
      <BottomAction />
      {!props.hide_meta && <Pagination />}
    </div>
    {props.children}
  </MainListProvider>
);

export default MainList;
