import classNames from "classnames";
import { Tooltip } from "react-tooltip";

const Tag = ({
  type,
  children,
  tooltip_id,
  tooltip_text,
  onClick,
}) => {
  const is_clickable = typeof onClick === "function";
  return (
    <>
      <span
        data-tooltip-id={tooltip_id}
        onClick={onClick}
        className={classNames("tag", {
          [`tag--${type}`]: type,
          [`tag--clickable`]: is_clickable,
        })}
      >
        {children}
      </span>
      <Tooltip
        id={tooltip_id}
        place="top"
        content={tooltip_text}
      />
    </>
  );
};

export default Tag;
