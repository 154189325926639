const order_notification_kinds = [
  { label: "Pakowanie", value: "packing_started" },
  { label: "Zakończono", value: "completed" },
  { label: "Opóźnienie", value: "missing_or_delayed" },
];

export default order_notification_kinds;

export const getOrderNotificationKind = (kind) =>
  order_notification_kinds.find(({ value }) => value === kind)?.label;
