import { createContext, useMemo, useContext } from "react";
import { PageLoader } from "components";
import useGet from "hooks/useGet";
import { useAuth } from "./auth";

const StandarizeOrdersWorker = "StandarizeOrdersWorker";
const ValidateOrdersWorker = "ValidateOrdersWorker";
const ReserveOrdersWorker = "ReserveOrdersWorker";
const ExportPackableOrdersWorker = "ExportPackableOrdersWorker";

const ExportOrderWaybillsWorker = "ExportOrderWaybillsWorker";
const ExportAmazonOrdersWaybillsWorker = "ExportAmazonOrdersWaybillsWorker";
const InvoiceOrdersWorker = "InvoiceOrdersWorker";
const CheckOrdersWaitingForInvoiceWorker = "CheckOrdersWaitingForInvoiceWorker";
const ExportOrderInvoicesWorker = "ExportOrderInvoicesWorker";
const VerifyOrdersWorker = "VerifyOrdersWorker";

const OrderStatusesContext = createContext();

const sidekiq_workers_by_status = {
  61: [StandarizeOrdersWorker],
  63: [ValidateOrdersWorker],
  65: [ReserveOrdersWorker],
  26: [ExportPackableOrdersWorker],
  72: [ExportOrderWaybillsWorker, ExportAmazonOrdersWaybillsWorker],
  68: [InvoiceOrdersWorker, CheckOrdersWaitingForInvoiceWorker],
  74: [ExportOrderInvoicesWorker],
  76: [VerifyOrdersWorker],
};

function OrderStatusesProvider({ children }) {
  const {
    user: {
      company: { expano_root_id },
    },
  } = useAuth();

  const { data, is_loading } = useGet({
    path: "/status_groups",
    query: ["status_groups"],
    options: { refetchInterval: 5_000 },
  });

  const all_statuses = [
    { id: null, name: "Wszystkie zamówienia" },
    ...(data?.statuses?.reduce(
      (agg = [], item = []) => [...agg, ...item.statuses],
      []
    ) || []),
  ];

  const getStatusName = (status_id) =>
    all_statuses.find(({ id }) => Number(id) === Number(status_id))?.name ||
    "-";

  const active_workers = useMemo(() => {
    if (!data?.active_workers) {
      return [];
    }
    return data?.active_workers?.filter(
      ({ company_id }) => Number(company_id) === Number(expano_root_id)
    );
  }, [data]);

  const statuses = useMemo(() => {
    if (!data?.statuses) {
      return [];
    }
    return data.statuses.map((item) => ({
      ...item,
      statuses: [...item.statuses].map((status) => {
        const status_workers = sidekiq_workers_by_status[status.id] || [];

        return {
          ...status,
          active_worker: active_workers.find(({ name }) =>
            status_workers.includes(name)
          ),
        };
      }),
    }));
  }, [data, active_workers]);

  const value = useMemo(() => {
    return {
      data: statuses,
      all_statuses,
      getStatusName,
    };
    // eslint-disable-next-line
  }, [data]);

  if (is_loading) {
    return <PageLoader />;
  }

  return (
    <OrderStatusesContext.Provider value={value}>
      {children}
    </OrderStatusesContext.Provider>
  );
}

const useOrderStatuses = () => useContext(OrderStatusesContext);
export { useOrderStatuses };
export default OrderStatusesProvider;
