const data = [
  {
    name: "Afganistan",
    code: "AF",
  },
  {
    name: "Albania",
    code: "AL",
  },
  {
    name: "Algieria",
    code: "DZ",
  },
  {
    name: "Andora",
    code: "AD",
  },
  {
    name: "Angola",
    code: "AO",
  },
  {
    name: "Anguilla",
    code: "AI",
  },
  {
    name: "Antarktyka",
    code: "AQ",
  },
  {
    name: "Antigua i Barbuda",
    code: "AG",
  },
  {
    name: "Arabia Saudyjska",
    code: "SA",
  },
  {
    name: "Argentyna",
    code: "AR",
  },
  {
    name: "Armenia",
    code: "AM",
  },
  {
    name: "Aruba",
    code: "AW",
  },
  {
    name: "Australia",
    code: "AU",
  },
  {
    name: "Austria",
    code: "AT",
  },
  {
    name: "Azerbejdżan",
    code: "AZ",
  },
  {
    name: "Bahamy",
    code: "BS",
  },
  {
    name: "Bahrajn",
    code: "BH",
  },
  {
    name: "Bangladesz",
    code: "BD",
  },
  {
    name: "Barbados",
    code: "BB",
  },
  {
    name: "Belgia",
    code: "BE",
  },
  {
    name: "Belize",
    code: "BZ",
  },
  {
    name: "Benin",
    code: "BJ",
  },
  {
    name: "Bermudy",
    code: "BM",
  },
  {
    name: "Bhutan",
    code: "BT",
  },
  {
    name: "Białoruś",
    code: "BY",
  },
  {
    name: "Boliwia",
    code: "BO",
  },
  {
    name: "Bonaire, Sint Eustatius i Saba",
    code: "BQ",
  },
  {
    name: "Bośnia i Hercegowina",
    code: "BA",
  },
  {
    name: "Botswana",
    code: "BW",
  },
  {
    name: "Brazylia",
    code: "BR",
  },
  {
    name: "Brunei",
    code: "BN",
  },
  {
    name: "Brytyjskie Terytorium Oceanu Indyjskiego",
    code: "IO",
  },
  {
    name: "Brytyjskie Wyspy Dziewicze",
    code: "VG",
  },
  {
    name: "Bułgaria",
    code: "BG",
  },
  {
    name: "Burkina Faso",
    code: "BF",
  },
  {
    name: "Burundi",
    code: "BI",
  },
  {
    name: "Chile",
    code: "CL",
  },
  {
    name: "Chiny",
    code: "CN",
  },
  {
    name: "Chorwacja",
    code: "HR",
  },
  {
    name: "Curaçao",
    code: "CW",
  },
  {
    name: "Cypr",
    code: "CY",
  },
  {
    name: "Czad",
    code: "TD",
  },
  {
    name: "Czarnogóra",
    code: "ME",
  },
  {
    name: "Czechy",
    code: "CZ",
  },
  {
    name: "Dalekie Wyspy Mniejsze Stanów Zjednoczonych",
    code: "UM",
  },
  {
    name: "Dania",
    code: "DK",
  },
  {
    name: "Demokratyczna Republika Konga",
    code: "CD",
  },
  {
    name: "Dominika",
    code: "DM",
  },
  {
    name: "Dominikana",
    code: "DO",
  },
  {
    name: "Dżibuti",
    code: "DJ",
  },
  {
    name: "Egipt",
    code: "EG",
  },
  {
    name: "Ekwador",
    code: "EC",
  },
  {
    name: "Erytrea",
    code: "ER",
  },
  {
    name: "Estonia",
    code: "EE",
  },
  {
    name: "Etiopia",
    code: "ET",
  },
  {
    name: "Falklandy",
    code: "FK",
  },
  {
    name: "Fidżi",
    code: "FJ",
  },
  {
    name: "Filipiny",
    code: "PH",
  },
  {
    name: "Finlandia",
    code: "FI",
  },
  {
    name: "Francja",
    code: "FR",
  },
  {
    name: "Francuskie Terytoria Południowe i Antarktyczne",
    code: "TF",
  },
  {
    name: "Gabon",
    code: "GA",
  },
  {
    name: "Gambia",
    code: "GM",
  },
  {
    name: "Georgia Południowa i Sandwich Południowy",
    code: "GS",
  },
  {
    name: "Ghana",
    code: "GH",
  },
  {
    name: "Gibraltar",
    code: "GI",
  },
  {
    name: "Grecja",
    code: "GR",
  },
  {
    name: "Grenada",
    code: "GD",
  },
  {
    name: "Grenlandia",
    code: "GL",
  },
  {
    name: "Gruzja",
    code: "GE",
  },
  {
    name: "Guam",
    code: "GU",
  },
  {
    name: "Guernsey",
    code: "GG",
  },
  {
    name: "Gujana Francuska",
    code: "GF",
  },
  {
    name: "Gujana",
    code: "GY",
  },
  {
    name: "Gwadelupa",
    code: "GP",
  },
  {
    name: "Gwatemala",
    code: "GT",
  },
  {
    name: "Gwinea Bissau",
    code: "GW",
  },
  {
    name: "Gwinea Równikowa",
    code: "GQ",
  },
  {
    name: "Gwinea",
    code: "GN",
  },
  {
    name: "Haiti",
    code: "HT",
  },
  {
    name: "Hiszpania",
    code: "ES",
  },
  {
    name: "Holandia",
    code: "NL",
  },
  {
    name: "Honduras",
    code: "HN",
  },
  {
    name: "Hongkong",
    code: "HK",
  },
  {
    name: "Indie",
    code: "IN",
  },
  {
    name: "Indonezja",
    code: "ID",
  },
  {
    name: "Irak",
    code: "IQ",
  },
  {
    name: "Iran",
    code: "IR",
  },
  {
    name: "Irlandia",
    code: "IE",
  },
  {
    name: "Islandia",
    code: "IS",
  },
  {
    name: "Izrael",
    code: "IL",
  },
  {
    name: "Jamajka",
    code: "JM",
  },
  {
    name: "Japonia",
    code: "JP",
  },
  {
    name: "Jemen",
    code: "YE",
  },
  {
    name: "Jersey",
    code: "JE",
  },
  {
    name: "Jordania",
    code: "JO",
  },
  {
    name: "Kajmany",
    code: "KY",
  },
  {
    name: "Kambodża",
    code: "KH",
  },
  {
    name: "Kamerun",
    code: "CM",
  },
  {
    name: "Kanada",
    code: "CA",
  },
  {
    name: "Katar",
    code: "QA",
  },
  {
    name: "Kazachstan",
    code: "KZ",
  },
  {
    name: "Kenia",
    code: "KE",
  },
  {
    name: "Kirgistan",
    code: "KG",
  },
  {
    name: "Kiribati",
    code: "KI",
  },
  {
    name: "Kolumbia",
    code: "CO",
  },
  {
    name: "Komory",
    code: "KM",
  },
  {
    name: "Kongo",
    code: "CG",
  },
  {
    name: "Korea Południowa",
    code: "KR",
  },
  {
    name: "Korea Północna",
    code: "KP",
  },
  {
    name: "Kostaryka",
    code: "CR",
  },
  {
    name: "Kuba",
    code: "CU",
  },
  {
    name: "Kuwejt",
    code: "KW",
  },
  {
    name: "Laos",
    code: "LA",
  },
  {
    name: "Lesotho",
    code: "LS",
  },
  {
    name: "Liban",
    code: "LB",
  },
  {
    name: "Liberia",
    code: "LR",
  },
  {
    name: "Libia",
    code: "LY",
  },
  {
    name: "Liechtenstein",
    code: "LI",
  },
  {
    name: "Litwa",
    code: "LT",
  },
  {
    name: "Luksemburg",
    code: "LU",
  },
  {
    name: "Łotwa",
    code: "LV",
  },
  {
    name: "Macedonia",
    code: "MK",
  },
  {
    name: "Madagaskar",
    code: "MG",
  },
  {
    name: "Majotta",
    code: "YT",
  },
  {
    name: "Makau",
    code: "MO",
  },
  {
    name: "Malawi",
    code: "MW",
  },
  {
    name: "Malediwy",
    code: "MV",
  },
  {
    name: "Malezja",
    code: "MY",
  },
  {
    name: "Mali",
    code: "ML",
  },
  {
    name: "Malta",
    code: "MT",
  },
  {
    name: "Mariany Północne",
    code: "MP",
  },
  {
    name: "Maroko",
    code: "MA",
  },
  {
    name: "Martynika",
    code: "MQ",
  },
  {
    name: "Mauretania",
    code: "MR",
  },
  {
    name: "Mauritius",
    code: "MU",
  },
  {
    name: "Meksyk",
    code: "MX",
  },
  {
    name: "Mikronezja",
    code: "FM",
  },
  {
    name: "Mjanma",
    code: "MM",
  },
  {
    name: "Mołdawia",
    code: "MD",
  },
  {
    name: "Monako",
    code: "MC",
  },
  {
    name: "Mongolia",
    code: "MN",
  },
  {
    name: "Montserrat",
    code: "MS",
  },
  {
    name: "Mozambik",
    code: "MZ",
  },
  {
    name: "Namibia",
    code: "NA",
  },
  {
    name: "Nauru",
    code: "NR",
  },
  {
    name: "Nepal",
    code: "NP",
  },
  {
    name: "Niemcy",
    code: "DE",
  },
  {
    name: "Niger",
    code: "NE",
  },
  {
    name: "Nigeria",
    code: "NG",
  },
  {
    name: "Nikaragua",
    code: "NI",
  },
  {
    name: "Niue",
    code: "NU",
  },
  {
    name: "Norfolk",
    code: "NF",
  },
  {
    name: "Norwegia",
    code: "NO",
  },
  {
    name: "Nowa Kaledonia",
    code: "NC",
  },
  {
    name: "Nowa Zelandia",
    code: "NZ",
  },
  {
    name: "Oman",
    code: "OM",
  },
  {
    name: "Pakistan",
    code: "PK",
  },
  {
    name: "Palau",
    code: "PW",
  },
  {
    name: "Palestyna",
    code: "PS",
  },
  {
    name: "Panama",
    code: "PA",
  },
  {
    name: "Papua-Nowa Gwinea",
    code: "PG",
  },
  {
    name: "Paragwaj",
    code: "PY",
  },
  {
    name: "Peru",
    code: "PE",
  },
  {
    name: "Pitcairn",
    code: "PN",
  },
  {
    name: "Polinezja Francuska",
    code: "PF",
  },
  {
    name: "Polska",
    code: "PL",
  },
  {
    name: "Portoryko",
    code: "PR",
  },
  {
    name: "Portugalia",
    code: "PT",
  },
  {
    name: "Republika Południowej Afryki",
    code: "ZA",
  },
  {
    name: "Republika Środkowoafrykańska",
    code: "CF",
  },
  {
    name: "Republika Zielonego Przylądka",
    code: "CV",
  },
  {
    name: "Reunion",
    code: "RE",
  },
  {
    name: "Rosja",
    code: "RU",
  },
  {
    name: "Rumunia",
    code: "RO",
  },
  {
    name: "Rwanda",
    code: "RW",
  },
  {
    name: "Sahara Zachodnia",
    code: "EH",
  },
  {
    name: "Saint Kitts i Nevis",
    code: "KN",
  },
  {
    name: "Saint Lucia",
    code: "LC",
  },
  {
    name: "Saint Vincent i Grenadyny",
    code: "VC",
  },
  {
    name: "Saint-Barthélemy",
    code: "BL",
  },
  {
    name: "Saint-Martin",
    code: "MF",
  },
  {
    name: "Saint-Pierre i Miquelon",
    code: "PM",
  },
  {
    name: "Salwador",
    code: "SV",
  },
  {
    name: "Samoa Amerykańskie",
    code: "AS",
  },
  {
    name: "Samoa",
    code: "WS",
  },
  {
    name: "San Marino",
    code: "SM",
  },
  {
    name: "Senegal",
    code: "SN",
  },
  {
    name: "Serbia",
    code: "RS",
  },
  {
    name: "Seszele",
    code: "SC",
  },
  {
    name: "Sierra Leone",
    code: "SL",
  },
  {
    name: "Singapur",
    code: "SG",
  },
  {
    name: "Sint Maarten",
    code: "SX",
  },
  {
    name: "Słowacja",
    code: "SK",
  },
  {
    name: "Słowenia",
    code: "SI",
  },
  {
    name: "Somalia",
    code: "SO",
  },
  {
    name: "Sri Lanka",
    code: "LK",
  },
  {
    name: "Stany Zjednoczone",
    code: "US",
  },
  {
    name: "Suazi",
    code: "SZ",
  },
  {
    name: "Sudan",
    code: "SD",
  },
  {
    name: "Sudan Południowy",
    code: "SS",
  },
  {
    name: "Surinam",
    code: "SR",
  },
  {
    name: "Svalbard i Jan Mayen",
    code: "SJ",
  },
  {
    name: "Syria",
    code: "SY",
  },
  {
    name: "Szwajcaria",
    code: "CH",
  },
  {
    name: "Szwecja",
    code: "SE",
  },
  {
    name: "Tadżykistan",
    code: "TJ",
  },
  {
    name: "Tajlandia",
    code: "TH",
  },
  {
    name: "Tajwan",
    code: "TW",
  },
  {
    name: "Tanzania",
    code: "TZ",
  },
  {
    name: "Timor Wschodni",
    code: "TL",
  },
  {
    name: "Togo",
    code: "TG",
  },
  {
    name: "Tokelau",
    code: "TK",
  },
  {
    name: "Tonga",
    code: "TO",
  },
  {
    name: "Trynidad i Tobago",
    code: "TT",
  },
  {
    name: "Tunezja",
    code: "TN",
  },
  {
    name: "Turcja",
    code: "TR",
  },
  {
    name: "Turkmenistan",
    code: "TM",
  },
  {
    name: "Turks i Caicos",
    code: "TC",
  },
  {
    name: "Tuvalu",
    code: "TV",
  },
  {
    name: "Uganda",
    code: "UG",
  },
  {
    name: "Ukraina",
    code: "UA",
  },
  {
    name: "Urugwaj",
    code: "UY",
  },
  {
    name: "Uzbekistan",
    code: "UZ",
  },
  {
    name: "Vanuatu",
    code: "VU",
  },
  {
    name: "Wallis i Futuna",
    code: "WF",
  },
  {
    name: "Watykan",
    code: "VA",
  },
  {
    name: "Wenezuela",
    code: "VE",
  },
  {
    name: "Węgry",
    code: "HU",
  },
  {
    name: "Wielka Brytania",
    code: "GB",
  },
  {
    name: "Wietnam",
    code: "VN",
  },
  {
    name: "Włochy",
    code: "IT",
  },
  {
    name: "Wybrzeże Kości Słoniowej",
    code: "CI",
  },
  {
    name: "Wyspa Bouveta",
    code: "BV",
  },
  {
    name: "Wyspa Bożego Narodzenia",
    code: "CX",
  },
  {
    name: "Wyspa Man",
    code: "IM",
  },
  {
    name: "Wyspa Świętej Heleny, Wyspa Wniebowstąpienia i Tristan da Cunha",
    code: "SH",
  },
  {
    name: "Wyspy Alandzkie",
    code: "AX",
  },
  {
    name: "Wyspy Cooka",
    code: "CK",
  },
  {
    name: "Wyspy Dziewicze Stanów Zjednoczonych",
    code: "VI",
  },
  {
    name: "Wyspy Heard i McDonalda",
    code: "HM",
  },
  {
    name: "Wyspy Kokosowe",
    code: "CC",
  },
  {
    name: "Wyspy Marshalla",
    code: "MH",
  },
  {
    name: "Wyspy Owcze",
    code: "FO",
  },
  {
    name: "Wyspy Salomona",
    code: "SB",
  },
  {
    name: "Wyspy Świętego Tomasza i Książęca",
    code: "ST",
  },
  {
    name: "Zambia",
    code: "ZM",
  },
  {
    name: "Zimbabwe",
    code: "ZW",
  },
  {
    name: "Zjednoczone Emiraty Arabskie",
    code: "AE",
  },
];

export const getNameByCountryCode = (country_code) =>
  data.find(({ code }) => code === country_code)?.name;

export const countries_options = data.map(
  ({ name, code }) => ({
    label: name,
    value: code,
  })
);
