import { countries_options, getNameByCountryCode } from "utils/countries_list";
import AddressForm from "./components/AddressForm";
import { useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import delivery_address_schema from "utils/validations/delivery_address_schema";
import invoice_address_schema from "utils/validations/invoice_address_schema";

const getDeliveryItems = (country_code) => [
  {
    label: "Imię i nazwisko",
    field: "delivery_fullname",
    field_type: "text",
  },
  {
    label: "Firma",
    field: "delivery_company",
    field_type: "text",
  },
  {
    label: "Ulica",
    field: "delivery_address",
    field_type: "text",
  },
  {
    label: "Kod pocztowy",
    field: "delivery_zipcode",
    field_type: "text",
  },
  {
    label: "Miasto",
    field: "delivery_city",
    field_type: "text",
  },
  {
    label: "Województwo",
    field: "delivery_state",
    field_type: "text",
  },
  {
    label: "Kraj",
    field: "delivery_country_code",
    field_label: getNameByCountryCode(country_code),
    field_type: "select",
    field_options: countries_options,
  },
];

const getInvoiceItems = (country_code) => [
  {
    label: "Klient chce fakturę",
    field: "is_invoice_needed",
    field_type: "checkbox",
    hide_info_row: true,
  },
  {
    label: "Imię i nazwisko",
    field: "invoice_fullname",
    field_type: "text",
  },
  {
    label: "Firma",
    field: "invoice_company",
    field_type: "text",
  },
  {
    label: "Ulica",
    field: "invoice_address",
    field_type: "text",
  },
  {
    label: "Kod pocztowy",
    field: "invoice_zipcode",
    field_type: "text",
  },
  {
    label: "Miasto",
    field: "invoice_city",
    field_type: "text",
  },
  {
    label: "Kraj",
    field: "invoice_country_code",
    field_label: getNameByCountryCode(country_code),
    field_type: "select",
    field_options: countries_options,
  },
  {
    label: "NIP",
    field: "invoice_nip",
    field_type: "text",
  },
];

const pick_up_items = [
  {
    label: "Nazwa",
    field: "delivery_point_name",
    field_type: "text",
  },
  {
    label: "ID",
    field: "delivery_point_id",
    field_type: "text",
  },
  {
    label: "Adres",
    field: "delivery_point_address",
    field_type: "text",
  },
  {
    label: "Kod pocztowy",
    field: "delivery_point_zipcode",
    field_type: "text",
  },
  {
    label: "Miasto",
    field: "delivery_point_city",
    field_type: "text",
  },
];

const Addresses = ({ refetch, is_fetching, ...data }) => {
  const delivery_items = useMemo(
    () => getDeliveryItems(data.delivery_country_code),
    [data.delivery_country_code]
  );

  const invoice_items = useMemo(
    () => getInvoiceItems(data.invoice_country_code),
    [data.invoice_country_code]
  );

  const delivery_alerts = useMemo(
    () => {
      const fields = delivery_items.flat().map(({ field }) => field);
      const errors = data.order_errors.filter(({ field_name }) =>
        fields.includes(field_name)
      );
      return errors.map(({ content }) => ({
        type: "danger",
        content: <p>{content}</p>,
      }));
    },
    //eslint-disable-next-line
    [data.order_errors, delivery_items]
  );

  const invoice_alerts = useMemo(
    () => {
      const fields = invoice_items.flat().map(({ field }) => field);
      const errors = data.order_errors.filter(({ field_name }) =>
        fields.includes(field_name)
      );
      return errors.map(({ content }) => ({
        type: "danger",
        content: <p>{content}</p>,
      }));
    },
    //eslint-disable-next-line
    [data.order_errors, invoice_items]
  );

  return (
    <div className="addresses-wrapper">
      <AddressForm
        {...{
          items: delivery_items,
          title: "Adres dostawy",
          refetch,
          is_fetching,
          alerts: delivery_alerts,
          validation_schema: delivery_address_schema,
          ...data,
        }}
      />
      <AddressForm
        {...{
          items: invoice_items,
          title: "Dane do faktury",
          refetch,
          is_fetching,
          validation_schema: invoice_address_schema,
          alerts: data?.is_invoice_needed
            ? [
                ...invoice_alerts,
                {
                  type: "warning",
                  content: (
                    <p>
                      <FontAwesomeIcon icon={faFileAlt} />
                      <b>Klient prosi o fakturę</b>
                    </p>
                  ),
                },
              ]
            : [],
          ...data,
        }}
      />
      <AddressForm
        {...{
          items: pick_up_items,
          title: "Odbiór w punkcie",
          refetch,
          is_fetching,
          ...data,
        }}
      />
    </div>
  );
};

export default Addresses;
