export const SET_DATA = "SET_DATA";
export const CHANGE_SORT = "CHANGE_SORT";
export const SET_TABLE_FILTER_VALUE =
  "SET_TABLE_FILTER_VALUE";
export const REMOVE_TABLE_FILTER_VALUE =
  "REMOVE_TABLE_FILTER_VALUE";
export const SET_MAIN_FILTER_VALUE =
  "SET_MAIN_FILTER_VALUE";
export const TOGGLE_MAIN_FILTERS = "TOGGLE_MAIN_FILTERS";
export const CLEAR_MAIN_FILTERS = "CLEAR_MAIN_FILTERS";

const mainListReducer = (state, { type, payload }) => {
  switch (type) {
    case SET_DATA: {
      return {
        ...state,
        is_initial: false,
        data: payload?.data || [],
        aggs: payload?.aggs || [],
        meta: payload?.meta || {},
      };
    }
    case CHANGE_SORT: {
      return {
        ...state,
        active_sort: payload,
      };
    }
    case TOGGLE_MAIN_FILTERS: {
      return {
        ...state,
        main_filters_open: !state.main_filters_open,
      };
    }

    case SET_TABLE_FILTER_VALUE: {
      const table_filters = [...state.table_filters];

      const index = table_filters.indexOf(
        table_filters?.find(
          (filter) => filter?.name === payload.name
        )
      );

      if (index > -1) {
        table_filters[index].values = [
          ...table_filters[index].values.filter(
            ({ value }) => value !== payload.value
          ),
          { value: payload.value, label: payload.label },
        ];
      }
      return {
        ...state,
        table_filters,
      };
    }

    case REMOVE_TABLE_FILTER_VALUE: {
      const table_filters = [...state.table_filters];
      const index = table_filters.indexOf(
        table_filters.find(
          (filter) => filter?.name === payload.name
        )
      );
      if (index > -1) {
        table_filters[index].values = [
          ...table_filters[index].values.filter(
            ({ value }) => value !== payload.value
          ),
        ];
      }
      return { ...state, table_filters };
    }

    case SET_MAIN_FILTER_VALUE: {
      const main_filters = [...state.main_filters];

      const index = main_filters.indexOf(
        main_filters?.find(
          (filter) => filter?.name === payload.name
        )
      );

      if (index > -1) {
        main_filters[index].values = payload.values;
      }
      return {
        ...state,
        main_filters,
      };
    }
    case CLEAR_MAIN_FILTERS: {
      const main_filters = [...state.main_filters].map(
        (item) => ({ ...item, values: [] })
      );

      return {
        ...state,
        main_filters,
      };
    }
    default:
      return state;
  }
};

export default mainListReducer;
