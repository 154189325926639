import axios from "axios";
import queryString from "query-string";

export const url =
  process.env.NODE_ENV === "production"
    ? "https://api.oms.tradesk.pl"
    : "http://0.0.0.0:3000";

export const wms_url = "https://api.wms.tradesk.pl";

export const api_url = `${url}/v2`;

export const api = axios.create({ baseURL: api_url });

api.interceptors.response.use(function (response) {
  return response.data;
});

export const createAxiosAuthorization = (access_token) => {
  api.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;
};

export const removeAxiosAuthorization = () => {
  delete api.defaults.headers.common["Authorization"];
};

export const handleErrors = (data) => {
  try {
    const errors = {};

    data?.forEach(({ detail, source: { pointer } }) => {
      errors[pointer.split("/")[pointer.split("/").length - 1]] = detail;
    });

    return errors;
  } catch (error) {
    return [];
  }
};

export const getApiErrorMessage = (error, message = "Wystąpił błąd") => {
  try {
    if (error?.response?.data?.errors?.length > 0) {
      if (typeof error.response.data.errors === "string") {
        return error.response.data.errors;
      }
      return error.response.data.errors
        .map(({ source: { pointer }, detail }) =>
          [pointer.split("/").pop(), detail].join(" ")
        )
        .join(", ");
    }
    if (error?.response?.data?.message) {
      return error.response.data.message;
    }
    if (error?.response?.data?.errors?.type) {
      return error.response.data.errors.type;
    }
    return message;
  } catch (error) {
    return message;
  }
};

export const stringifyQuery = (data) =>
  decodeURI(queryString.stringify(data, { arrayFormat: "bracket" }));

export const parseQuery = (data) =>
  queryString.parse(data, { arrayFormat: "bracket" });
