import classNames from "classnames";

const Dashboard = ({
  className,
  inner,
  header,
  children,
  footer,
  full,
}) => {
  return (
    <div
      className={classNames("dashboard", className, {
        inner,
        full,
      })}
    >
      {header?.title && (
        <header className="dashboard__header">
          <h2 className="dashboard__title heading">
            {header.title}
          </h2>
          {header.extra}
        </header>
      )}
      {children}
      {footer && (
        <div className="dashboard__footer">{footer}</div>
      )}
    </div>
  );
};

export default Dashboard;
