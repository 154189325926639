import Layout from "components/Layout";
import { useOrderStatuses } from "context/providers/order_statuses";
import StatusesList from "./components/StatusesList";
import PagePanel from "./components/PagePanel";

const OrderLayout = ({ page, status_id, children, show_statuses = true }) => {
  const { data: statuses } = useOrderStatuses();
  return (
    <Layout>
      <PagePanel data={page} />
      <div className="orders-layout">
        {show_statuses && (
          <StatusesList status_id={status_id} data={statuses} />
        )}
        <div className="order-box">{children}</div>
      </div>
    </Layout>
  );
};

export default OrderLayout;
