import { Fragment, useState } from "react";

import { useMainList } from "components/MainList/context/provider";

import { ReactComponent as EditIcon } from "icons/edit.svg";
import { ReactComponent as RemoveIcon } from "icons/remove.svg";
import { ReactComponent as PreviewIcon } from "icons/preview.svg";
import { ReactComponent as CollapseIcon } from "icons/collapse.svg";

import SubList from "./components/SubList";
import classNames from "classnames";

const renderActionButtonIcon = (type) => {
  switch (type) {
    case "edit":
      return <EditIcon />;
    case "remove":
      return <RemoveIcon />;
    case "preview":
      return <PreviewIcon />;
    default:
      return null;
  }
};

const TableRows = () => {
  const {
    data,
    renderItem,
    with_actions,
    has_sub_list,
    renderActions,
    subListData,
    handleNavigate,
    activeIndexes,
  } = useMainList();

  const [sub_lists_open, setSubListsOpen] = useState([]);
  const active_indexes = activeIndexes?.(data) || [];
  const with_navigate =
    typeof handleNavigate === "function";

  const toggleSubList = (index) => {
    if (sub_lists_open.includes(index)) {
      setSubListsOpen(
        sub_lists_open.filter((el) => el !== index)
      );
    } else {
      setSubListsOpen([...sub_lists_open, index]);
    }
  };

  return (
    <tbody>
      {data?.map((item, index) => {
        const sub_list_data = subListData?.(item) || {};
        const has_sub_list_data =
          sub_list_data?.data?.length > 0;
        const is_sub_list_open =
          sub_lists_open.includes(index);
        return (
          <Fragment key={item?.id || index}>
            <tr
              className={classNames({
                active: active_indexes?.includes(index),
                has_sub_list,
                with_navigate,
                is_sub_list_open,
                empty: !has_sub_list_data,
              })}
              style={
                with_navigate ||
                (has_sub_list && has_sub_list_data)
                  ? { cursor: "pointer" }
                  : {}
              }
              onMouseUp={
                with_navigate
                  ? (e) => handleNavigate(item, e)
                  : undefined
              }
              onClick={
                has_sub_list && has_sub_list_data
                  ? () => toggleSubList(index)
                  : with_navigate
                  ? () => handleNavigate(item)
                  : undefined
              }>
              {has_sub_list && (
                <td className="collapse-icon">
                  <CollapseIcon />
                </td>
              )}
              {renderItem(item)}
              {with_actions && (
                <td className="col-actions">
                  {renderActions?.(item)?.map(
                    ({ type, handleClick }, index) => (
                      <button
                        type="button"
                        key={index}
                        className="btn-icon"
                        onMouseDown={(e) => {
                          if (with_navigate) {
                            e.stopPropagation();
                          }
                          handleClick(e);
                        }}>
                        {renderActionButtonIcon(type)}
                      </button>
                    )
                  )}
                </td>
              )}
            </tr>
            {has_sub_list && (
              <SubList
                parent_data={item}
                {...sub_list_data}
                is_open={is_sub_list_open}
              />
            )}
          </Fragment>
        );
      })}
    </tbody>
  );
};

export default TableRows;
