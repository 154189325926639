import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useStats } from "context/providers/stats";
import { Tabs } from "components";
import { useState } from "react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Filler
);

const options = {
  plugins: {
    tooltip: {
      enabled: true,
      intersect: false,
      callbacks: {
        label: function ({ dataIndex, dataset, parsed }) {
          const date = dataset.dates[dataIndex];
          return `${dataset.label}: ${parsed.y}${date ? ` (${date})` : ""}`;
        },
      },
    },
  },
  responsive: false,
  interaction: {
    mode: "index",
    intersect: false,
  },
  stacked: false,
  scales: {
    y: {
      type: "linear",
      display: true,
      position: "right",
      min: 0,
    },
    y1: {
      type: "linear",
      display: true,
      position: "left",
      min: 0,
      grid: {
        drawOnChartArea: false,
      },
    },
  },
};

const tabs = [
  { name: "Wszystkie", id: "all" },
  { name: "Poniedziałek", id: "1" },
  { name: "Wtorek", id: "2" },
  { name: "Środa", id: "3" },
  { name: "Czwartek", id: "4" },
  { name: "Piątek", id: "5" },
  { name: "Sobota", id: "6" },
  { name: "Niedziela", id: "0" },
];

const OrdersHourlyChart = () => {
  const current_week_day = new Date().getDay();
  const [week_type, setWeekType] = useState(String(current_week_day));
  const {
    state: {
      data: { hourly_orders, today_hourly_orders },
    },
  } = useStats();

  const labels = hourly_orders?.orders_hourly_values
    ? Object.keys(hourly_orders.orders_hourly_values)
    : [];

  const orders_hourly_values = hourly_orders?.orders_hourly_values
    ? Object.values(hourly_orders.orders_hourly_values)
    : [];

  const today_orders_hourly_values =
    today_hourly_orders?.today_orders_hourly_values
      ? Object.values(today_hourly_orders.today_orders_hourly_values)
      : [];

  const orders_hourly_increases = hourly_orders?.orders_hourly_increases
    ? Object.values(hourly_orders.orders_hourly_increases)
    : [];

  const today_orders_hourly_increases =
    today_hourly_orders?.today_orders_hourly_increases
      ? Object.values(today_hourly_orders.today_orders_hourly_increases)
      : [];

  const data = {
    labels,
    datasets: [
      {
        label: "Rekordowa ilość",
        data: orders_hourly_values.map((item) => item?.[week_type]?.count),
        fill: false,
        borderWidth: 2,
        borderColor: "rgb(242, 102, 37)",
        backgroundColor: "rgba(242, 102, 37, 0.2)",
        yAxisID: "y",
        dates: orders_hourly_values.map((item) => item?.[week_type]?.date),
      },
      {
        label: "Ilość dzisiejsza",
        data: today_orders_hourly_values,
        fill: false,
        borderWidth: 2,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.2)",
        yAxisID: "y",
        dates: [],
      },
      {
        label: "Rekordowy przyrost",
        type: "bar",
        data: orders_hourly_increases.map((item) => item?.[week_type]?.count),
        fill: false,
        borderWidth: 2,
        borderColor: "rgb(242, 102, 37)",
        backgroundColor: "rgba(242, 102, 37, 0.2)",
        yAxisID: "y1",
        dates: orders_hourly_increases.map((item) => item?.[week_type]?.date),
      },
      {
        label: "Przyrost dzisiejszy",
        type: "bar",
        data: today_orders_hourly_increases,
        fill: false,
        borderWidth: 2,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.2)",
        yAxisID: "y1",
        dates: [],
      },
    ],
  };

  return (
    <>
      <Tabs
        data={tabs}
        onClick={(id) => setWeekType(id)}
        active_id={week_type}
      />
      <div className="chart-box__body">
        <div className="chart-box__chart">
          <p>
            Najwższa wartość:{" "}
            <strong>
              {Math.max(
                ...Object.values(hourly_orders?.orders_hourly_values || {}).map(
                  (item) => item?.[week_type]?.count
                )
              )}
            </strong>
          </p>
          <p>
            Najwższy przyrost:{" "}
            <strong>
              {Math.max(
                ...Object.values(
                  hourly_orders?.orders_hourly_increases || {}
                ).map((item) => item?.[week_type]?.count)
              )}
            </strong>
          </p>
          <Line
            width={window.innerWidth - 160}
            height="350px"
            options={options}
            data={data}
          />
        </div>
      </div>
    </>
  );
};

export default OrdersHourlyChart;
