import { ReactComponent as ShopIcon } from "icons/shop.svg";

const nav = [
  {
    name: "Zamówienia",
    type: "group",
    icon: <ShopIcon />,
    items: [
      {
        name: "Lista zamówień",
        path: "/orders",
        protected: false,
      },
      // {
      //   name: "Szybka wysyłka",
      //   path: "/orders/fast-delivery",
      //   protected: false,
      // },
      {
        name: "Statystyki",
        path: "/stats",
        protected: true,
      },
    ],
  },
];

export default nav;
