import classNames from "classnames";
import { useMainList } from "components/MainList/context/provider";

import { ReactComponent as ArrowIcon } from "icons/sort-arrow.svg";

const checkDirectionToChange = ({
  is_current_column,
  active_direction,
}) => {
  if (is_current_column) {
    return active_direction === "asc" ? "desc" : "asc";
  }
  return "desc";
};

const TableSort = () => {
  const {
    state: { sort, active_sort },
    with_actions,
    has_sub_list,
    changeSort,
  } = useMainList();
  return (
    <thead className="table-sort">
      <tr>
        {has_sub_list && <th width="38" />}
        {sort.map(
          ({
            label = "",
            name = "",
            extra,
            sortable = true,
            style = {},
          }) =>
            sortable ? (
              <th style={style} key={name}>
                <button
                  type="button"
                  onClick={() =>
                    changeSort({
                      column: name,
                      direction: checkDirectionToChange({
                        is_current_column:
                          active_sort.column === name,
                        active_direction:
                          active_sort.direction,
                      }),
                    })
                  }
                  className={classNames("sort-button", {
                    active: active_sort.column === name,
                    asc:
                      active_sort.column === name &&
                      active_sort.direction === "asc",
                  })}>
                  <span>{label}</span>
                  <ArrowIcon />
                </button>
              </th>
            ) : (
              <th style={style} key={name}>
                <span>{label}</span>
                {extra && <p className="extra">{extra}</p>}
              </th>
            )
        )}
        {with_actions && <th />}
      </tr>
    </thead>
  );
};

export default TableSort;
