const Head = ({ data }) => (
  <thead className="main-table__head">
    <tr>
      {data.map(
        ({
          label,
          name,
          
          style = {},
        }) => (
          <th key={name} style={style}>
            <span>{label}</span>
          </th>
        )
      )}
    </tr>
  </thead>
);
export default Head;
