import { useRef } from "react";
import ReactDOM from "react-dom";
import { Button } from "expano-components";

const Modal = ({
  children,
  header,
  footer,
  handleClose,
  size = null,
}) => {
  const ref = useRef();

  return ReactDOM.createPortal(
    <div
      className="popup-wrapper"
      onClick={(e) => {
        if (ref?.current) {
          if (!ref.current.contains(e.target)) {
            handleClose();
          }
        }
      }}>
      <div
        ref={ref}
        className={`popup ${
          Boolean(size) ? size : ""
        } active`}>
        <header className="popup__header">
          <div className="title">
            {Boolean(header?.icon) && (
              <div className="icon">{header?.icon}</div>
            )}
            <h2>{header?.title}</h2>
          </div>
          <Button
            type="close"
            className="popup-close"
            onClick={handleClose}
          />
        </header>
        <div className="popup__body">{children}</div>
        {footer && (
          <div className="popup__footer">{footer}</div>
        )}
      </div>
    </div>,
    document.querySelector("#root")
  );
};

export default Modal;
