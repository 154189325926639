const styles = ({ is_errored }) => ({
  control: (styles) => ({
    ...styles,
    borderColor: is_errored ? "#ff0000" : "#d1d5dc",
    minHeight: 27,
  }),
  input: (provided) => ({
    ...provided,
    fontFamily: "Poppins",
    fontSize: "11px",
    maxHeight: 20,
    padding: 0,
  }),
  option: (provided) => ({
    ...provided,
    fontFamily: "Poppins",
    padding: "2px 10px",
    fontSize: 11,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0 10px",
  }),
  singleValue: (provided) => ({
    ...provided,
    fontFamily: "Poppins",
    fontSize: 13,
    color: "#2d353d",
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none",
  }),
  placeholder: (provided) => ({
    ...provided,
    fontFamily: "Poppins",
    fontSize: 13,
  }),
});

export default styles;
