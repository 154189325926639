import { Chart } from "react-google-charts";

const ChartComponent = ({ data, label, events = [] }) => {
  if (!data) {
    return null;
  }

  return (
    <div className="chart-item">
      <p className="form-label">{label}</p>
      <Chart
        options={{
          colors: ["#c70000"],
          chartArea: {
            width: '100%',
            left: 30,
            top: 20,
            bottom: 25,
            right: 0
          },
          vAxis: {
            textStyle: {
              fontSize: 13,
              color: "#595D6E",
            },
          },
          hAxis: {
            textStyle: {
              fontSize: 13,
              color: "#595D6E",
            },
          },
          legend: { position: "none" },
        }}
        chartType="ColumnChart"
        loader={<p>Trwa ładowanie...</p>}
        width="400px"
        height="200px"
        data={data}
        chartEvents={events}
      />
    </div>
  );
};

export default ChartComponent;
