import ReactSelect from "react-select";
import { useMainList } from "components/MainList/context/provider";

const styles = {
  control: (styles) => ({
    ...styles,
    borderColor: "#E2E5EC",
    borderWidth: 1,
    borderRadius: 4,
    minHeight: 24,
    width: 150,
  }),
  option: (provided) => ({
    ...provided,
    fontFamily: "Poppins",
    fontSize: "11px",
    lineHeight: "15px",
    padding: "5px 10px",
  }),
  singleValue: (provided) => ({
    ...provided,
    fontFamily: "Poppins",
    fontSize: "11px",
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "1px 10px",
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none",
  }),
  placeholder: (provided) => ({
    ...provided,
    fontFamily: "Poppins",
    fontSize: "11px",
  }),
};

const dir_options = [
  { label: "Rosnąco", value: "asc" },
  { label: "Malejąco", value: "desc" },
];

const Sort = () => {
  const {
    state: { active_sort },
    sort_options,
    changeSort,
  } = useMainList();

  return (
    <div className="sort-group">
      <ReactSelect
        onChange={({ value: column }) =>
          changeSort({
            column,
            direction: active_sort.direction,
          })
        }
        styles={styles}
        placeholder={"Wybierz..."}
        options={sort_options}
        value={sort_options.find(
          ({ value }) => value === active_sort.column
        )}
      />
      <ReactSelect
        onChange={({ value: direction }) =>
          changeSort({
            direction,
            column: active_sort.column,
          })
        }
        styles={styles}
        options={dir_options}
        value={dir_options.find(
          ({ value }) => value === active_sort.direction
        )}
      />
    </div>
  );
};

export default Sort;
