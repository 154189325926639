import {Watch} from "react-loader-spinner";

const PanelLoader = () => {
  return (
    <div className="panel-loader">
      <div className="loader-holder">
        <Watch color="#ffbf00" height={40} width={40} />
      </div>
    </div>
  );
};

export default PanelLoader;
