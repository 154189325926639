import { useEffect, useMemo, useRef, useState } from "react";
import { Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import useUpdate from "hooks/useUpdate";
import { Dashboard } from "components";
import Actions from "../Actions";
import SelectField from "./components/SelectField";
import CheckField from "./components/CheckField";
import Alert from "../../../Alert";
import getGusData from "utils/getGusData";

const AddressForm = ({
  title,
  items = [],
  alerts = [],
  validation_schema,
  refetch,
  is_fetching,
  ...data
}) => {
  const [is_getting_gus_data, setGettingGusData] = useState(false);
  const [editing_field, setEditingField] = useState(null);
  const ref = useRef();

  const { is_saving, update } = useUpdate({
    path: `/orders/${data.id}`,
    onSuccess: async () => {
      await refetch();
      setEditingField(null);
    },
  });

  useEffect(() => {
    if (!!editing_field) {
      setTimeout(() => {
        ref.current.querySelector(`input[name="${editing_field}"]`)?.focus();
      }, 100);
    }
  }, [editing_field]);

  const initial_values = useMemo(
    () =>
      Object.fromEntries(
        items
          .flat()
          .filter(({ field_type }) => field_type !== "none")
          .map(({ field, field_value }) => [
            field,
            field_value || data?.[field],
          ])
      ),
    [items, data]
  );

  const onSubmit = async (values, { setSubmitting }) => {
    await update(values);
    setSubmitting(false);
  };

  const fillDataFromGus = async (nip, setFieldValue) => {
    try {
      setGettingGusData(true);
      const gus_data = await getGusData(nip);
      setFieldValue("invoice_company", gus_data?.name || "");
      setFieldValue("invoice_address", gus_data?.street_address || "");
      setFieldValue("invoice_zipcode", gus_data?.zip_code || "");
      setFieldValue("invoice_city", gus_data?.city || "");
      setFieldValue("invoice_country_code", gus_data?.country_code);
    } catch (error) {
      toast(
        error?.response?.data?.message ||
          "Wystąpił błąd podczas pobierania danych",
        { type: "error" }
      );
    } finally {
      setGettingGusData(false);
    }
  };

  const copyFromDeliveryAddress = (setFieldValue) => {
    setFieldValue("invoice_company", data?.delivery_company || "");
    setFieldValue("invoice_fullname", data?.delivery_fullname || "");
    setFieldValue("invoice_address", data?.delivery_address || "");
    setFieldValue("invoice_zipcode", data?.delivery_zipcode);
    setFieldValue("invoice_city", data?.delivery_city);
    setFieldValue("invoice_country_code", data?.delivery_country_code);
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initial_values}
        validationSchema={validation_schema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue, isValid }) => (
          <Dashboard
            header={{
              title,
            }}
            footer={
              editing_field && (
                <Actions
                  is_valid={isValid}
                  is_saving={is_saving}
                  is_fetching={is_fetching}
                  setEditingField={setEditingField}
                />
              )
            }
          >
            {!editing_field &&
              alerts.map(({ type, content }) => (
                <Alert type={type}>{content}</Alert>
              ))}
            <div className="order-details__info" ref={ref}>
              {editing_field ? (
                <Form>
                  {items
                    .filter(({ field_type }) => field_type === "checkbox")
                    .map(({ label, field }) => (
                      <CheckField key={field} name={field} label={label} />
                    ))}
                  <table className="info-group">
                    <tbody>
                      {items
                        .filter(({ field_type }) => field_type !== "checkbox")
                        .map(
                          ({
                            label,
                            field,
                            field_type,
                            field_label,
                            field_options = [],
                          }) => (
                            <tr key={field}>
                              <td>{label}:</td>
                              <td>
                                {field_type === "none" ? (
                                  field_label
                                ) : field_type === "select" ? (
                                  <SelectField
                                    name={field}
                                    options={field_options}
                                  />
                                ) : (
                                  <Field name={field}>
                                    {({ field }) => (
                                      <input
                                        type={field_type}
                                        placeholder={label}
                                        {...field}
                                      />
                                    )}
                                  </Field>
                                )}
                              </td>
                            </tr>
                          )
                        )}
                      {Object.keys(values).includes("invoice_nip") && (
                        <>
                          <tr>
                            <td></td>
                            <td>
                              <button
                                className="btn btn-sm btn-blue"
                                style={{
                                  padding: "2px 5px",
                                  fontSize: 12,
                                }}
                                type="button"
                                disabled={
                                  is_getting_gus_data ||
                                  values?.invoice_nip?.length < 9
                                }
                                onClick={() =>
                                  fillDataFromGus(
                                    values?.invoice_nip,
                                    setFieldValue
                                  )
                                }
                              >
                                {is_getting_gus_data
                                  ? "Trwa pobieranie..."
                                  : "Pobierz dane z GUS"}
                              </button>
                            </td>
                          </tr>{" "}
                          <tr>
                            <td></td>
                            <td>
                              <button
                                className="btn btn-sm btn-blue"
                                style={{
                                  padding: "2px 5px",
                                  fontSize: 12,
                                }}
                                type="button"
                                onClick={() =>
                                  copyFromDeliveryAddress(setFieldValue)
                                }
                              >
                                Kopiuj z adresu dostawy
                              </button>
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </Form>
              ) : (
                <table className="info-group">
                  <tbody>
                    {items.map(
                      ({
                        label,
                        field,
                        field_label,
                        field_type,
                        hide_info_row = false,
                      }) => (
                        <tr
                          key={field}
                          style={hide_info_row ? { display: "none" } : {}}
                        >
                          <td>{label}:</td>
                          <td>
                            {field_type === "none" ? (
                              field_label
                            ) : (
                              <button
                                onClick={() => setEditingField(field)}
                                type="button"
                                className="editable"
                              >
                                <div className="icon">
                                  <FontAwesomeIcon icon={faEdit} />
                                </div>
                                {field_label || data?.[field] || "..."}
                              </button>
                            )}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </Dashboard>
        )}
      </Formik>
    </>
  );
};

export default AddressForm;
