import { Routes, Route, Navigate } from "react-router-dom";

import { OrdersList, OrderEdit, StatsDashboard } from "./pages";

import { useAuth } from "context/providers/auth";
import FastDeliveryOrdersList from "pages/orders/FastDelivery";

const App = () => {
  const {
    user: { is_admin },
  } = useAuth();

  return (
    <Routes>
      <Route exact path="/" element={<Navigate to="/orders" />} />
      <Route
        exact
        path="/stats"
        element={is_admin ? <StatsDashboard /> : <Navigate to="/orders" />}
      />
      <Route exact path="/orders" element={<OrdersList />} />
      <Route
        exact
        path="/orders/fast-delivery"
        element={<FastDeliveryOrdersList />}
      />
      <Route exact path="/orders/:id" element={<OrderEdit />} />
      <Route path="*" element={<Navigate to="/orders" />} />
    </Routes>
  );
};

export default App;
