import { useState } from "react";
import moment from "moment";
import { Button } from "expano-components";
import { Dashboard, Modal, Tag } from "components";
import { getOrderNotificationKind } from "utils/options/order_notification_kinds";

const OrderNotifications = ({ data }) => {
  const [content_preview, setContentPreview] = useState(null);
  return (
    <Dashboard header={{ title: "Powiadomienia e-mail" }} className="m-t-5">
      {content_preview && (
        <Modal
          size="xxl"
          handleClose={() => setContentPreview(null)}
          header={{ title: "Podgląd e-mail" }}
          footer={
            <Button
              type="button"
              text="Zamknij"
              onClick={() => setContentPreview(null)}
            />
          }
        >
          <iframe
            title="Podgląd e-mail"
            src={"data:text/html," + encodeURIComponent(content_preview)}
            width="100%"
            height="620"
          />
        </Modal>
      )}
      <ul className="dashboard__list">
        {data.map(
          ({
            id,
            subject,
            kind,
            content,
            created_at,
            to_email,
            attachments_json,
          }) => {
            const attachments_json_data = JSON.parse(attachments_json);
            return (
              <li key={id} className="df ai-c jc-sb">
                <div>
                  <p className="text-muted">
                    <span className="m-r-10">
                      {moment(created_at).format("DD.MM.YYYY")}{" "}
                      {moment(created_at).format("HH:mm")}
                    </span>
                    <Tag>{getOrderNotificationKind(kind)}</Tag>
                  </p>
                  <p className="m-t-5">{subject}</p>
                  <p className="text-muted text-small m-t-5">Do: {to_email}</p>
                  {attachments_json_data?.map(({ filename, content_url }) => (
                    <p key={filename}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={content_url}
                        class="text-link"
                      >
                        {filename}
                      </a>
                    </p>
                  ))}
                </div>
                <button
                  type="button"
                  className="btn btn-sm btn-blue"
                  onClick={() => setContentPreview(content)}
                >
                  Podgląd
                </button>
              </li>
            );
          }
        )}
      </ul>
    </Dashboard>
  );
};

export default OrderNotifications;
