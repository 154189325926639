import { useMainList } from "components/MainList/context/provider";
import { Button } from "expano-components";

const BottomAction = () => {
  const { bottom_action } = useMainList();
  if (!bottom_action?.name) return null;
  return (
    <div className="main-list__bottom-action">
      <Button
        type="add"
        text={bottom_action.name}
        onClick={bottom_action.onClick}
      />
    </div>
  );
};

export default BottomAction;
