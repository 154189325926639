import { useField } from "formik";

const CheckField = ({ name, label, disabled }) => {
  const [field] = useField({
    name,
    type: "checkbox",
  });

  return (
    <div
      className={`check-group ${
        disabled ? "disabled" : ""
      }`}>
      <input
        disabled={disabled}
        id={field.name}
        {...field}
        type="checkbox"
        defaultChecked={field.value}
      />
      <label htmlFor={field.name} className="label-form">
        {label}
      </label>
    </div>
  );
};

export default CheckField;
