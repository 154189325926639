import { useOrderStatuses } from "context/providers/order_statuses";
import { useMemo, useState } from "react";
import Select from "./components/Select";

import { Oval } from "react-loader-spinner";
import useCreate from "hooks/useCreate";
import { useParams } from "react-router-dom";

const bok_statuses = [47, 50, 51, 52];
const cancel_statuses = [32, 33, 34, 48];

const OrderStatusSelect = ({ id, refetch }) => {
  const { id: order_id } = useParams();
  const [status_id, setStatusId] = useState(id);
  const { all_statuses } = useOrderStatuses();

  const options = useMemo(() => {
    let options_data = [];
    if (bok_statuses.includes(id)) {
      options_data = all_statuses.filter(({ id }) =>
        bok_statuses.includes(id)
      );
    }
    if (cancel_statuses.includes(id)) {
      options_data = all_statuses.filter(({ id }) =>
        cancel_statuses.includes(id)
      );
    }

    return options_data.map(({ id, name, color }) => ({
      label: (
        <div className="order-status">
          <span
            className="order-status__box"
            style={{ backgroundColor: color }}
          />
          <span className="order-status__name">{name}</span>
        </div>
      ),
      value: id,
    }));
  }, [id]);

  const { is_saving, create: changeStatus } = useCreate({
    path: `/orders/${order_id}/change_status`,
    onSuccess: refetch,
    success_message: "Zmiana statusu przebiegła pomyślnie",
  });

  return (
    <div className="order-status-select">
      <Select
        options={options}
        value={status_id}
        onChange={({ value }) => setStatusId(value)}
      />
      <button
        type="button"
        disabled={is_saving || status_id === id}
        onClick={() => changeStatus({ status_id })}>
        {is_saving ? (
          <Oval
            height={20}
            width={20}
            color="#ffbf00"
            visible={true}
            secondaryColor="#ffbf00"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        ) : (
          <span>Przenieś</span>
        )}
      </button>
    </div>
  );
};

export default OrderStatusSelect;
