import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { api } from "utils/api";

const useDelete = ({ path, onSuccess }) => {
  const { isLoading: is_deleting, mutateAsync: _delete } =
    useMutation({
      mutationFn: () => api.delete(path),
      onSuccess: () => {
        toast("Usunięto pomyślnie", { type: "success" });
        onSuccess?.();
      },
      onError: (err) => {
        toast(
          err?.response?.data?.message ||
            err?.response?.statusText ||
            "Wystąpił błąd",
          { type: "error" }
        );
      },
    });

  return { is_deleting, _delete };
};

export default useDelete;
