import moment from "moment";
import "chart.js/auto";
import { useStats } from "context/providers/stats";
import { Dashboard } from "components";
import Select from "../Select";
import DatePicker from "../DatePicker";

import { getNameByCountryCode } from "utils/countries_list";
import OrdersChart from "./components/OrdersChart";
import ChannelsChart from "./components/ChannelsChart";
import CountriesChart from "./components/CountriesChart";
import OrdersHourlyChart from "./components/OrdersHourlyChart";

const interval_options = [
  { label: "Godzinny", value: "hour" },
  { label: "Dzienny", value: "day" },
  { label: "Tygodniowy", value: "week" },
  { label: "Miesięczny", value: "month" },
  { label: "Roczny", value: "year" },
];

const date_options = [
  { label: "Wczoraj", value: "yesterday" },
  { label: "Dzisiaj", value: "today" },
  { label: "Ostatnie 7 dni", value: "last_7_days" },
  { label: "Ostatnie 14 dni", value: "last_14_days" },
  { label: "Ostatnie 30 dni", value: "last_30_days" },
  { label: "Ten miesiąc", value: "current_month" },
  { label: "Zeszły miesiąc", value: "last_month" },
  { label: "Ten rok", value: "this_year" },
  { label: "Zeszły rok", value: "last_year" },
];

const getHistogramDates = (value) => {
  switch (value) {
    case "yesterday":
      return {
        histogram_range_from: moment().subtract(1, "day").startOf("day"),
        histogram_range_to: moment().subtract(1, "day").endOf("day"),
      };
    case "today":
      return {
        histogram_range_from: moment().startOf("day"),
        histogram_range_to: moment().endOf("day"),
      };
    case "last_7_days":
      return {
        histogram_range_from: moment().subtract(7, "day").startOf("day"),
        histogram_range_to: moment().endOf("day"),
      };
    case "last_14_days":
      return {
        histogram_range_from: moment().subtract(14, "day").startOf("day"),
        histogram_range_to: moment().endOf("day"),
      };
    case "last_30_days":
      return {
        histogram_range_from: moment().subtract(30, "day").startOf("day"),
        histogram_range_to: moment().endOf("day"),
      };

    case "current_month":
      return {
        histogram_range_from: moment().startOf("month"),
        histogram_range_to: moment().endOf("month"),
      };
    case "last_month":
      return {
        histogram_range_from: moment().subtract(1, "month").startOf("month"),
        histogram_range_to: moment().subtract(1, "month").endOf("month"),
      };
    case "this_year":
      return {
        histogram_range_from: moment().startOf("year"),
        histogram_range_to: moment().endOf("year"),
      };
    case "last_year":
      return {
        histogram_range_from: moment().subtract(1, "year").startOf("year"),
        histogram_range_to: moment().subtract(1, "year").endOf("year"),
      };
    default:
      break;
  }
};

const Charts = () => {
  const {
    state: {
      is_updating,
      query,
      data: { country_codes, channels },
    },
    setQuery,
  } = useStats();

  const country_options = country_codes.map(({ name, value }) => ({
    label: `${getNameByCountryCode(name)} (${name})`,
    value: value,
  }));

  const channel_options = channels.map(({ name, value }) => ({
    label: name,
    value: value,
  }));

  return (
    <>
      <Dashboard header={{ title: "Statystyki zamówień" }}>
        <div className="chart-box__body">
          <div className="chart-box__form">
            <Select
              label="Kraj"
              is_multi={true}
              disabled={is_updating}
              onChange={(values) =>
                setQuery({
                  ...query,
                  histogram_country_code: values.map(({ value }) => value),
                })
              }
              options={country_options}
              value={country_options.filter(({ value }) =>
                query.histogram_country_code.includes(value)
              )}
            />
            <Select
              label="Kanał"
              is_multi={true}
              disabled={is_updating}
              onChange={(values) =>
                setQuery({
                  ...query,
                  histogram_channel_id: values.map(({ value }) => value),
                })
              }
              options={channel_options}
              value={channel_options.filter(({ value }) =>
                query.histogram_channel_id.includes(Number(value))
              )}
            />
            <Select
              label="Typ"
              disabled={is_updating}
              onChange={({ value: histogram_interval }) =>
                setQuery({
                  ...query,
                  histogram_interval,
                })
              }
              options={interval_options}
              value={interval_options.find(
                ({ value }) => value === query.histogram_interval
              )}
            />
            <Select
              label="Zakres"
              disabled={is_updating}
              onChange={({ value }) =>
                setQuery({
                  ...query,
                  histogram_date_option: value,
                  ...getHistogramDates(value),
                })
              }
              options={date_options}
              value={date_options.find(
                ({ value }) => value === query.histogram_date_option
              )}
            />
            <DatePicker
              name="ordered_at"
              label="Data zamówienia"
              onChange={(value) =>
                setQuery({
                  ...query,
                  histogram_range_from: value?.[0],
                  histogram_range_to: value?.[1],
                })
              }
              value={[query.histogram_range_from, query.histogram_range_to]}
            />
          </div>
          <div className="chart-box__chart">
            <OrdersChart />
          </div>
          <div className="row">
            <div className="box-2">
              <ChannelsChart />
            </div>
            <div className="box-2">
              <CountriesChart />
            </div>
          </div>
        </div>
      </Dashboard>
      <Dashboard header={{ title: "Statystyki godzinowe" }}>
        <OrdersHourlyChart />
      </Dashboard>
    </>
  );
};

export default Charts;
