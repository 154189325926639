import { useLocation } from "react-router-dom";
import { Pagination as ExpanoPagination } from "expano-components";
import { useMainList } from "components/MainList/context/provider";

const Pagination = () => {
  const { meta, setMetaPagination } = useMainList();
  const location = useLocation();

  if (!meta.total_count) {
    return null;
  }
  return (
    <div className="main-list__pagination">
      <ExpanoPagination
        navigate_after_change={false}
        data={meta}
        location={location}
        handlePaginate={(page, per_page) =>
          setMetaPagination({ page, per_page })
        }
      />
    </div>
  );
};

export default Pagination;
