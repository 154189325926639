import { object, string } from "yup";

const delivery_address_schema = object({
  delivery_fullname: string().required("Pole wymagane"),
  delivery_address: string().required("Pole wymagane"),
  delivery_zipcode: string().required("Pole wymagane"),
  delivery_city: string().required("Pole wymagane"),
  delivery_country_code: string().required("Pole wymagane"),
});

export default delivery_address_schema;
