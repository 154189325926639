import { useEffect, useState } from "react";
import {
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";

const MainSearch = () => {
  const [search_params] = useSearchParams();
  const navigate = useNavigate();

  const [value, setValue] = useState(
    search_params.get("search") || ""
  );

  useEffect(() => {
    setValue(search_params.get("search") || "");
  }, [search_params]);

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate(`/orders?search=${value}`);
  };

  return (
    <form className="main-search" onSubmit={handleSubmit}>
      <div className="main-search__icon">
        <FontAwesomeIcon icon={faSearch} />
      </div>
      <input
        className="main-search__input"
        id="main_search"
        value={value}
        onChange={({ target }) => setValue(target.value)}
        type="text"
        placeholder="Szukaj..."
      />
      <button className="main-search__submit" type="submit">
        <FontAwesomeIcon icon={faArrowRight} />
      </button>
    </form>
  );
};

export default MainSearch;
