import { Dashboard } from "components";
import moment from "moment";

const LogsList = ({ data }) => (
  <Dashboard header={{ title: "Logi" }}>
    <ul
      className="dashboard__list scroll"
      style={{ maxHeight: 600 }}
    >
      {data.map(({ id, created_at, content, user }) => (
        <li key={id}>
          <p className="text-info">
            {created_at
              ? moment(created_at).format(
                  "DD.MM.YYYY HH:mm"
                )
              : ""}
            {" - "}
            {user?.full_name}
          </p>
          <p className="text-muted">{content || "-"}</p>
        </li>
      ))}
    </ul>
  </Dashboard>
);

export default LogsList;
