import { ClipboardCopy } from "components";
import { Tooltip } from "react-tooltip";
import renderDate from "utils/renderDate";

const Header = ({
  id,
  delivery_fullname,
  ordered_at,
  created_at,
  external_id,
}) => (
  <div className="order-details__header">
    <h1>
      <span>Zamówienie</span> <ClipboardCopy id={id} text={id} />
    </h1>
    <p>
      <ClipboardCopy id={external_id} text={external_id} />, {delivery_fullname}
      ,{" "}
      <span className="clipboard-text" data-tooltip-id="create_date">
        {renderDate(ordered_at)}
      </span>
      <Tooltip id="create_date" place="left" content={renderDate(created_at)} />
    </p>
  </div>
);

export default Header;
