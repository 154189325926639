import { MainTable } from "components";
import OrderLine from "./components/OrderLine";
import CreateOrderLineModal from "./components/CreateOrderLineModal";
import { useCallback } from "react";

const OrderLines = ({
  currency_code,
  errors,
  refetch,
  data = [],
  can_edit,
}) => {
  const getError = useCallback(
    (order_line_id) => {
      const validation_error = errors.find(
        ({ field_name }) => {
          const [type, id] = field_name
            ? field_name.split(".")
            : "";
          return (
            type === "order_line" &&
            order_line_id === Number(id)
          );
        }
      );
      return validation_error?.content || null;
    },
    [errors]
  );

  return (
    <>
      <MainTable
        data={data}
        head={[
          {
            label: "",
            name: "image",
            style: { width: 80 },
          },
          {
            label: "ID produktu",
            name: "id",
            style: { width: 120 },
          },
          {
            label: "Nazwa produktu",
            name: "name",
          },
          {
            label: "Komentarz",
            name: "comment",
            style: { width: 150 },
          },
          {
            label: "Ilość",
            name: "quantity",
            style: { width: 60 },
          },
          {
            label: "Cena",
            name: "price",
            style: { width: 120 },
          },
          can_edit && {
            label: (
              <CreateOrderLineModal
                currency_code={currency_code}
                refetch={refetch}
              />
            ),
            name: "action",
            style: { width: 120 },
          },
        ].filter(Boolean)}
        renderRow={(item) => (
          <OrderLine
            key={item.id}
            {...item}
            currency_code={currency_code}
            refetch={refetch}
            error={getError(item.id)}
            can_edit={can_edit}
          />
        )}
      />
    </>
  );
};

export default OrderLines;
