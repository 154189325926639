import { useSearchParams } from "react-router-dom";
import { MainList, OrderLayout } from "components";

import {
  sort,
  table_filters,
  main_filters,
  createOrderedAtData,
} from "./const/data";

import { ReactComponent as ShopIcon } from "icons/shop.svg";

import { useOrderStatuses } from "context/providers/order_statuses";
import useCreate from "hooks/useCreate";
import useGet from "hooks/useGet";
import ChartComponent from "./components/Chart";
import moment from "moment";
import OrderItem from "../components/OrderItem";

const OrdersList = () => {
  const { getStatusName } = useOrderStatuses();
  const [search_params] = useSearchParams();
  const status_id = search_params.get("status_id");
  const search = search_params.get("search");

  const status_name = getStatusName(status_id);

  const { data: missing_skus } = useGet({
    path: "/orders/missing_skus",
    query: ["missing_skus"],
    options: {
      enabled: [78].includes(Number(status_id)),
    },
  });

  const { is_saving: is_rereserving_all, create: reReserveAll } = useCreate({
    path: "/orders/rereserve_all",
    success_message: "Ponowienie rezerwacji przebiegło pomyślnie",
  });

  const { is_saving: is_reissuing_invoice_all, create: reIssueInvoiceAll } =
    useCreate({
      path: "/orders/reissue_invoice_all",
      success_message: "Ponowienie fakturowania przebiegło pomyślnie",
    });

  const { is_saving: is_reexporting_invoice_all, create: reExportInvoiceAll } =
    useCreate({
      path: "/orders/reexport_invoice_all",
      success_message: "Ponowienie eksportu faktur przebiegło pomyślnie",
    });

  const {
    is_saving: is_reexporting_packable_all,
    create: reExportPackableAll,
  } = useCreate({
    path: "/orders/reexport_packable_all",
    success_message:
      "Ponowienie eksportu zamówień do wms'a przebiegło pomyślnie",
  });

  return (
    <OrderLayout
      status_id={status_id}
      page={{
        name: "Zamówienia",
        icon: <ShopIcon />,
        breadcrumbs: [
          {
            name: "Zamówienia",
          },
        ],
      }}
    >
      <MainList
        title={search ? `Wyniki wyszukiwania '${search}'` : status_name}
        query_key={["orders", status_id, search]}
        query_reset_filters_key="status_id"
        header_aside={
          <>
            {Number(status_id) === 66 && (
              <button
                style={{
                  padding: "5px 7px",
                }}
                disabled={is_rereserving_all}
                type="button"
                className="btn btn-sm btn-blue"
                onClick={() => reReserveAll()}
              >
                {is_rereserving_all ? "Proszę czekać..." : "Ponów wszystkie"}
              </button>
            )}
            {Number(status_id) === 69 && (
              <button
                style={{
                  padding: "5px 7px",
                }}
                disabled={is_reissuing_invoice_all}
                type="button"
                className="btn btn-sm btn-blue"
                onClick={() => reIssueInvoiceAll()}
              >
                {is_reissuing_invoice_all
                  ? "Proszę czekać..."
                  : "Ponów wszystkie"}
              </button>
            )}
            {Number(status_id) === 75 && (
              <button
                style={{
                  padding: "5px 7px",
                }}
                disabled={is_reexporting_invoice_all}
                type="button"
                className="btn btn-sm btn-blue"
                onClick={() => reExportInvoiceAll()}
              >
                {is_reexporting_invoice_all
                  ? "Proszę czekać..."
                  : "Ponów wszystkie"}
              </button>
            )}
            {Number(status_id) === 71 && (
              <button
                style={{
                  padding: "5px 7px",
                }}
                disabled={is_reexporting_packable_all}
                type="button"
                className="btn btn-sm btn-blue"
                onClick={() => reExportPackableAll()}
              >
                {is_reexporting_packable_all
                  ? "Proszę czekać..."
                  : "Ponów wszystkie"}
              </button>
            )}
          </>
        }
        custom_filters={[
          ...(status_id
            ? [
                {
                  name: "status_id",
                  values: [{ value: status_id }],
                },
              ]
            : []),
          ...(search
            ? [
                {
                  name: "main_search",
                  values: [{ value: search }],
                },
              ]
            : []),
        ]}
        api_path={"/orders"}
        search_type="elastic"
        sort_options={[
          { label: "ID", value: "id" },
          { label: "Kwota", value: "total_price" },
          { label: "Data zamówienia", value: "ordered_at" },
          {
            label: "Data w statusie",
            value: "status_changed_at",
          },
        ]}
        sort_column="ordered_at"
        sort_direction="desc"
        default_per_page={20}
        sort={sort}
        table_filters={table_filters}
        main_filters={main_filters}
        visible_filters={[
          [78, 67, 30].includes(Number(status_id)) && {
            name: "ordered_at",
            type: "chart",
            renderChart: ({ data, setMainFilterValue }) => {
              const ordered_at_data = createOrderedAtData(data);
              return (
                <ChartComponent
                  data={ordered_at_data}
                  label="Opóźnienie"
                  events={[
                    {
                      eventName: "select",
                      callback({ chartWrapper }) {
                        const selected = chartWrapper.getChart().getSelection();

                        const index = selected?.[0]?.row;
                        const today_morning = moment().startOf("day");
                        const today_evening = moment().endOf("day");

                        const handleFilter = (value) =>
                          setMainFilterValue({
                            name: "ordered_at",
                            values: [
                              {
                                value,
                              },
                            ],
                          });

                        switch (index) {
                          case 5:
                            handleFilter([today_morning, today_evening]);
                            break;
                          case 4:
                            handleFilter([
                              today_morning.subtract(1, "day"),
                              today_evening.subtract(1, "day"),
                            ]);
                            break;
                          case 3:
                            handleFilter([
                              today_morning.subtract(2, "day"),
                              today_evening.subtract(2, "day"),
                            ]);
                            break;
                          case 2:
                            handleFilter([
                              today_morning.subtract(3, "day"),
                              today_evening.subtract(3, "day"),
                            ]);
                            break;
                          case 1:
                            handleFilter([
                              today_morning.subtract(4, "day"),
                              today_evening.subtract(4, "day"),
                            ]);
                            break;
                          case 0:
                            handleFilter([
                              null,
                              today_evening.subtract(5, "day"),
                            ]);
                            break;
                          default:
                            break;
                        }
                      },
                    },
                  ]}
                />
              );
            },
          },
          [78].includes(Number(status_id)) && {
            label: "Brakujące sku",
            name: "product_sku",
            type: "tags",
            options:
              missing_skus?.map((item) => {
                const sku = Object.keys(item)[0];
                const qty = Object.values(item)[0];
                return {
                  label: `${sku} (${qty})`,
                  value: sku,
                };
              }) || [],
          },
        ].filter(Boolean)}
        renderItem={OrderItem}
        empty_text="Brak zamówień w wybranym statusie"
      />
    </OrderLayout>
  );
};

export default OrdersList;
