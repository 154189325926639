import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";

const ClipboardCopy = ({ id, text }) => {
  return (
    <>
      <CopyToClipboard
        text={text}
        onCopy={() => toast.success("Skipiowano do schowka!")}>
        <span className="clipboard-text" data-tooltip-id={id}>{text}</span>
      </CopyToClipboard>
      <Tooltip
        id={id}
        place="left"
        content="Kopiuj do schowka"
      />
    </>
  );
};

export default ClipboardCopy;
