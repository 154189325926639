import { useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWarning,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";

const getIcon = (type) => {
  switch (type) {
    case "warning":
      return faWarning;
    case "danger":
      return faExclamationCircle;
    default:
      return faWarning;
  }
};

const Alert = ({ type, children }) => {
  const icon = useMemo(() => getIcon(type), [type]);
  return (
    <div className={`order-details__alert ${type}`}>
      <div className="alert-icon">
        <FontAwesomeIcon icon={icon} />
      </div>
      <div className="alert-content">{children}</div>
    </div>
  );
};

export default Alert;
