import { FormikInput, Modal } from "components";
import { Button } from "expano-components";
import { Formik, Form } from "formik";
import order_line_schema from "utils/validations/order_line_schema";

const EditOrderLineModal = ({
  currency_code,
  initial_values,
  handleClose,
  onSubmit,
  is_saving,
}) => (
  <Formik
    initialValues={initial_values}
    validationSchema={order_line_schema}
    onSubmit={onSubmit}
  >
    {({ dirty, submitForm, isValid }) => (
      <Modal
        header={{ title: "Edytuj pozycję" }}
        handleClose={handleClose}
        footer={
          <>
            <Button text="Anuluj" onClick={handleClose} />
            <Button
              disabled={!dirty || is_saving || !isValid}
              type="save"
              text={is_saving ? "Proszę czekać" : "Zapisz"}
              onClick={submitForm}
            />
          </>
        }
      >
        <Form>
          <FormikInput label="SKU" type="text" name="product_sku" />
          <FormikInput label="Ilość" type="number" name="quantity" />
          <FormikInput
            label="Cena"
            type="number"
            name="unit_price"
            postfix={currency_code}
          />
          <FormikInput label="Komentarz" type="text" name="comment" />
        </Form>
      </Modal>
    )}
  </Formik>
);

export default EditOrderLineModal;
