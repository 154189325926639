import LazyLoad from "react-lazy-load";
import { Tooltip } from "react-tooltip";
const ProductImage = ({
  id,
  image,
  thumb,
  quantity,
  alt,
}) => {
  return (
    <>
      {image && (
        <Tooltip
          id={id}
          place="right"
          variant="light"
          content={
            <LazyLoad height={200} offset={200}>
              <img
                style={{ width: 200, height: 200 }}
                alt={alt}
                src={image}
              />
            </LazyLoad>
          }
        />
      )}
      <LazyLoad height={45} offset={45}>
        <div
          data-tooltip-id={id}
          className="product-image"
          style={
            thumb
              ? {
                  backgroundImage: `url(${thumb})`,
                }
              : {
                  backgroundImage:
                    "url('/empty_photo.png')",
                }
          }>
          {quantity > 1 && (
            <span className="product-image__count">
              {quantity}
            </span>
          )}
        </div>
      </LazyLoad>
    </>
  );
};

export default ProductImage;
