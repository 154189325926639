const { Button } = require("expano-components");
const { useFormikContext } = require("formik");

const FormActions = ({ is_saving, is_fetching, setEditingField }) => {
  const { handleSubmit, dirty } = useFormikContext();

  return (
    <div className="button-group">
      <Button
        type="button"
        text="Anuluj"
        onClick={() => setEditingField(false)}
      />
      <Button
        disabled={is_fetching || is_saving || !dirty}
        type="save"
        text={is_fetching || is_saving ? "Proszę czekać..." : "Zapisz"}
        onClick={() => handleSubmit()}
      />
    </div>
  );
};

export default FormActions;
