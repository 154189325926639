import { useMainList } from "../../context/provider";

const Header = () => {
  const { title, header_aside } = useMainList();
  if (!title) return null;

  return (
    <div className="main-list__header">
      <div className="header-wrapper">
        <h2 className="header-title">{title}</h2>
      </div>
      <div className="header-aside">{header_aside}</div>
    </div>
  );
};

export default Header;
