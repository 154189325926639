import { saveAs } from "file-saver";
import { useEffect, useMemo, useRef, useState } from "react";
import { Field, Form, Formik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import useUpdate from "hooks/useUpdate";
import { Dashboard, Tag } from "components";
import Actions from "../FormActions";

const getTagName = ({ number, kind }) => {
  if (!number) {
    return "";
  }
  const is_par = number.startsWith("PA");
  const is_fv = number.startsWith("FS");
  if (is_fv) {
    if (kind === "sales_document") {
      return "Faktura sprzedażowa";
    }
    if (kind === "correction_document") {
      return "Faktura korygująca";
    }
  }
  if (is_par) {
    if (kind === "sales_document") {
      return "Paragon sprzedażowy";
    }
    if (kind === "correction_document") {
      return "Paragon korygujacy";
    }
  }
  return null;
};

const DocumentInfo = ({ refetch, is_fetching, ...data }) => {
  const [editing_field, setEditingField] = useState(null);
  const ref = useRef();

  const { is_saving, update } = useUpdate({
    path: `/orders/${data.id}`,
    onSuccess: async () => {
      await refetch();
      setEditingField(null);
    },
  });

  const items = [
    [
      {
        label: "Nr rezerwacji",
        field: "erp_reservation_number",
        field_label: data?.erp_reservation_number,
        field_type: "none",
      },
      {
        label: "Nr faktury",
        field: "erp_invoice_number",
        field_label: data?.erp_invoice_number,
        field_type: "none",
      },
      {
        label: "Nr wydania (WZ)",
        field: "erp_dispatch_number",
        field_label: data?.erp_dispatch_number,
        field_type: "none",
      },
    ],
  ];

  useEffect(() => {
    if (!!editing_field) {
      setTimeout(() => {
        ref.current.querySelector(`input[name="${editing_field}"]`)?.focus();
      }, 100);
    }
  }, [editing_field]);

  const initial_values = useMemo(
    () =>
      Object.fromEntries(
        items
          .flat()
          .filter(({ field_type }) => field_type !== "none")
          .map(({ field, field_value }) => [
            field,
            field_value || data?.[field],
          ])
      ),
    //eslint-disable-next-line
    [data]
  );

  const onSubmit = (values) => update(values);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initial_values}
        onSubmit={onSubmit}
      >
        <Dashboard
          header={{
            title: "Dokumenty",
          }}
          footer={
            editing_field && (
              <Actions
                is_saving={is_saving}
                is_fetching={is_fetching}
                setEditingField={setEditingField}
              />
            )
          }
        >
          <div className="order-details__info" ref={ref}>
            {editing_field ? (
              <Form>
                {items.map((group, index) => (
                  <table className="info-group" key={index}>
                    <tbody>
                      {group.map(
                        ({ label, field, field_type, field_label }) => (
                          <tr key={field}>
                            <td>{label}:</td>
                            <td>
                              {field_type === "none" ? (
                                field_label
                              ) : (
                                <Field name={field}>
                                  {({ field }) => (
                                    <input
                                      type={field_type}
                                      placeholder={label}
                                      {...field}
                                    />
                                  )}
                                </Field>
                              )}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                ))}
              </Form>
            ) : (
              items.map((group, index) => (
                <table className="info-group" key={index}>
                  <tbody>
                    {group.map(({ label, field, field_label, field_type }) => (
                      <tr key={field}>
                        <td>{label}:</td>
                        <td>
                          {field_type === "none" ? (
                            field_label
                          ) : (
                            <button
                              onClick={() => setEditingField(field)}
                              type="button"
                              className="editable"
                            >
                              <div className="icon">
                                <FontAwesomeIcon icon={faEdit} />
                              </div>
                              {field_label || data?.[field] || "..."}
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ))
            )}
          </div>
          <ul className="dashboard__list">
            {data?.invoices?.map(({ id, number, kind, document_url }) => {
              const tag_name = getTagName({ number, kind });
              return (
                <li key={id}>
                  <div className="df jc-sb">
                    {/* eslint-disable-next-line */}
                    <a
                      href="#"
                      className="text-info"
                      onClick={() => saveAs(document_url, `${number}.pdf`)}
                    >
                      {number}
                    </a>
                    <Tag type="info">{tag_name}</Tag>
                  </div>
                </li>
              );
            })}
          </ul>
        </Dashboard>
      </Formik>
    </>
  );
};

export default DocumentInfo;
