import { MainList, OrderLayout } from "components";

import { sort, table_filters } from "./const/data";

import { ReactComponent as ShopIcon } from "icons/shop.svg";

import OrderLine from "../components/OrderItem";

const FastDeliveryOrdersList = () => {
  return (
    <OrderLayout
      show_statuses={false}
      page={{
        name: "Szybka wysyłka",
        icon: <ShopIcon />,
        breadcrumbs: [
          {
            name: "Zamówienia",
            path: "/orders",
          },
          {
            name: "Szybka wysyłka",
          },
        ],
      }}
    >
      <MainList
        title="Szybka wysyłka"
        query_key={["orders"]}
        query_reset_filters_key="status_id"
        api_path={"/orders/fast_delivery"}
        search_type="elastic"
        sort_options={[
          { label: "ID", value: "id" },
          { label: "Kwota", value: "total_price" },
          { label: "Data zamówienia", value: "ordered_at" },
          {
            label: "Data w statusie",
            value: "status_changed_at",
          },
        ]}
        sort_column="ordered_at"
        sort_direction="desc"
        default_per_page={20}
        sort={sort}
        table_filters={table_filters}
        main_filters={[]}
        visible_filters={[]}
        renderItem={OrderLine}
        empty_text="Brak zamówień szybkiej wysyłki"
      />
    </OrderLayout>
  );
};

export default FastDeliveryOrdersList;
