const renderPrice = (price, currency = "PLN") => {
  if (!price) {
    return ["0,00", currency].join(" ");
  }
  const price_formatted = parseFloat(price)
    .toFixed(2)
    .replace(".", ",");

  return [price_formatted, currency].join(" ");
};

export default renderPrice;
