import { useMainList } from "components/MainList/context/provider";

import { ReactComponent as RemoveFilterIcon } from "icons/remove-filter.svg";

const FilterValues = ({ name, data }) => {
  const { removeTableFilterValue } = useMainList();
  return (
    <div className="filter-values">
      {data.map(({ label, value }) => (
        <button
          key={value}
          className="btn-remove-filter"
          type="button"
          onClick={() =>
            removeTableFilterValue({ name, value })
          }>
          <RemoveFilterIcon />
          <span>{label}</span>
        </button>
      ))}
    </div>
  );
};

export default FilterValues;
