import ReactSelect from "react-select";

const styles = {
  control: (styles) => ({
    ...styles,
    borderColor: "#D7D7DA",
    minHeight: 14,
    padding: "0 3px",
    borderRadius: "5px 0 0 5px",
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
  }),
  option: (provided) => ({
    ...provided,
    fontFamily: "Poppins",
    fontSize: "11px",
    padding: 5,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 4,
  }),
  singleValue: (provided) => ({
    ...provided,
    fontFamily: "Poppins",
    fontSize: "11px",
    lineHeight: "14px",
    padding: 0,
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none",
  }),
  placeholder: (provided) => ({
    ...provided,
    fontFamily: "Poppins",
    fontSize: "11px",
    lineHeight: "14px",
    color: "#D7D7DA",
  }),
};

const Select = ({ onChange, value, options = [] }) => (
  <ReactSelect
    onChange={onChange}
    styles={styles}
    options={options}
    value={options.find((option) => option.value === value)}
    placeholder={"Wybierz status..."}
  />
);

export default Select;
