const Icon = ({ date, count }) => {
  if (Boolean(date) && count === 0) {
    return "✅";
  }
  if (count > 0) {
    return "❌";
  }
  return "🕑";
};

const ErrorBox = ({ title, date, aside, data = [] }) => {
  return (
    <div className="error-box">
      <div className="error-box__header">
        <div className="header-box">
          <span className="icon">
            <Icon date={date} count={data.length} />
          </span>
          <strong>{title}</strong>
        </div>
        <div className="header-box">
          <span>{aside}</span>
        </div>
      </div>
      <ul className="error-box__body">
        {data.map(({ id, field_name, content }) => (
          <li key={id}>
            <p className="title">{field_name}</p>
            <p>{content}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ErrorBox;
