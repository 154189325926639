import ReactSelect from "react-select";
import { useField } from "formik";
import styles from "./styles";

const SelectField = ({
  name,
  options = [],
  is_disabled = false,
}) => {
  const [field, meta, { setValue }] = useField({
    name,
    type: "text",
  });
  const is_errored = meta.touched && meta.error;
  const custom_styles = styles({is_errored});

  return (
    <ReactSelect
      placeholder="Wybierz..."
      styles={custom_styles}
      value={options?.find(
        ({ value }) => value === field.value
      )}
      isSearchable={true}
      menuShouldScrollIntoView={true}
      options={options}
      isDisabled={is_disabled}
      onChange={({ value }) => setValue(value)}
      theme={(theme) => ({
        ...theme,
        fontFamily: "Poppins",
        borderRadius: 4,
        colors: {
          ...theme.colors,
          neutral20: "#E2E5EC",
        },
        error: is_errored,
      })}
    />
  );
};

export default SelectField;
