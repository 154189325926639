import moment from "moment";

export const SET_DATA = "SET_DATA";
export const SET_QUERY = "SET_QUERY";

export const initial_state = {
  is_loading: true,
  is_updating: false,
  query: {
    histogram_country_code: [],
    histogram_channel_id: [],
    histogram_interval: "day",
    histogram_date_option: "last_30_days",
    histogram_range_from: moment()
      .subtract(30, "day")
      .startOf("day"),
    histogram_range_to: moment().endOf("day"),
  },
  data: {
    orders_by_ordered_at: [],
    country_codes: [],
    channels: [],
  },
};

const authReducer = (
  state = initial_state,
  { type, payload }
) => {
  switch (type) {
    case SET_DATA: {
      return {
        ...state,
        is_updating: false,
        is_loading: false,
        data: payload.data,
      };
    }
    case SET_QUERY: {
      return {
        ...state,
        is_updating: true,
        query: { ...state.query, ...payload.query },
      };
    }

    default:
      return state;
  }
};

export default authReducer;
