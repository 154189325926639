import React from "react";

import { PageLoader, Layout } from "components";

import StatsProvider, {
  useStats,
} from "context/providers/stats";

import Charts from "./components/Charts";

import { ReactComponent as DashboardIcon } from "icons/dashboard.svg";

const StatsDashboard = () => {
  const {
    state: { is_loading },
    is_error,
  } = useStats();

  if (is_loading || is_error) {
    return (
      <Layout
        page={{
          name: "Statystyki",
          icon: <DashboardIcon />,
          breadcrumbs: [],
        }}>
        <PageLoader />
      </Layout>
    );
  }

  return (
    <Layout
      page={{
        name: "Statystyki",
        icon: <DashboardIcon />,
        breadcrumbs: [],
      }}>
      <Charts />
    </Layout>
  );
};

export default function StatsStatsProvider() {
  return (
    <StatsProvider>
      <StatsDashboard />
    </StatsProvider>
  );
}
