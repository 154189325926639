import { useMainList } from "components/MainList/context/provider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
const TableEmpty = () => {
  const { empty_text } = useMainList();
  return (
    <div className="main-list__empty">
      <FontAwesomeIcon
        style={{ color: "#737373", fontSize: 50 }}
        icon={faWindowClose}
      />
      <p>{empty_text}</p>
    </div>
  );
};

export default TableEmpty;
