import {
  createContext,
  useMemo,
  useContext,
  useEffect,
  useReducer,
} from "react";
import useGet from "hooks/useGet";
import reducer, {
  initial_state,
  SET_DATA,
  SET_QUERY,
} from "../reducers/stats";
import moment from "moment";

const StatsContext = createContext();

function StatsProvider({ children }) {
  const [state, dispatch] = useReducer(
    reducer,
    initial_state
  );

  const parseQuery = ({
    histogram_channel_id,
    histogram_country_code,
    histogram_range_from,
    histogram_range_to,
    histogram_interval
  }) => {
    const new_query = {
      histogram_channel_id,
      histogram_country_code,
      histogram_interval
    };
    if (histogram_range_from) {
      new_query["histogram_range_from"] = moment(
        histogram_range_from
      ).format();
    }
    if (histogram_range_to) {
      new_query["histogram_range_to"] = moment(
        histogram_range_to
      ).format();
    }

    return new_query;
  };

  const { data, is_error } = useGet({
    path: "/stats/dashboard",
    search: parseQuery(state.query),
    query: ["dashboard", state.query],
  });

  useEffect(() => {
    if (data) {
      dispatch({ type: SET_DATA, payload: { data } });
    }
  }, [data]);

  const setQuery = (query) =>
    dispatch({ type: SET_QUERY, payload: { query } });

  const value = useMemo(() => {
    return {
      state,
      is_error,
      setQuery,
    };
    // eslint-disable-next-line
  }, [state]);

  return (
    <StatsContext.Provider value={value}>
      {children}
    </StatsContext.Provider>
  );
}

const useStats = () => useContext(StatsContext);
export { useStats };
export default StatsProvider;
