import { useState } from "react";
import OrderLine from "./components/OrderLine";

const max_lines = 4;

const OrderLines = ({
  data,
  currency_code,
  is_standarized,
  errors,
}) => {
  const [is_collapsed, setCollapsed] = useState(true);
  const order_line_count = data.length;

  const handleClick = () => setCollapsed(!is_collapsed);
  
  return (
    <>
      {(is_collapsed ? data.slice(0, max_lines) : data).map(
        (item) => (
          <OrderLine
            key={item.id}
            currency_code={currency_code}
            is_standarized={is_standarized}
            has_error={
              !!errors.find(({ field_name }) =>
                field_name?.includes(item.id)
              )
            }
            {...item}
          />
        )
      )}
      {order_line_count > max_lines && (
        <>
          ...
          <button
            className="btn-collapse-lines"
            type="button"
            onClick={handleClick}>
            W sumie {order_line_count} przedmiotów -{" "}
            {is_collapsed ? "pokaż" : "ukryj"} pozostałe
          </button>
        </>
      )}
    </>
  );
};

export default OrderLines;
