import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { api, getApiErrorMessage } from "utils/api";

const useUpdate = ({
  path,
  onSuccess,
  onError,
  success_message = "Zaktualizowano pomyślnie",
}) => {
  const { isPending: is_saving, mutateAsync: update } =
    useMutation({
      mutationFn: (values) => api.put(path, values),
      onSuccess: ({ data }) => {
        toast(success_message, {
          type: "success",
        });
        onSuccess?.(data);
      },
      onError: (err) => {
        toast(getApiErrorMessage(err), {
          type: "failure",
        });
        onError?.();
      },
    });

  return { is_saving, update };
};

export default useUpdate;
