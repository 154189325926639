const OrderStatus = ({ color, name }) => (
  <div className="order-status">
    <span
      className="order-status__box"
      style={{ backgroundColor: color }}
    />
    <span className="order-status__name">{name}</span>
  </div>
);

export default OrderStatus;
