import moment from "moment";
import { Dashboard, Tag } from "components";
import renderPrice from "utils/renderPrice";

const BillingsList = ({ data }) => (
  <Dashboard header={{ title: "Opłaty" }} className="m-t-5">
    <ul className="dashboard__list">
      {data.map(
        ({
          id,
          external_name,
          currency_gross_value,
          currency,
          occured_at,
        }) => (
          <li key={id} className="df ai-c jc-sb">
            <div>
              <p>{external_name}</p>
              <p className="text-muted">
                {moment(occured_at).format("DD.MM.YYYY")}
              </p>
            </div>
            <Tag>
              {renderPrice(currency_gross_value, currency)}
            </Tag>
          </li>
        )
      )}
    </ul>
  </Dashboard>
);

export default BillingsList;
