import { Pie } from "react-chartjs-2";
import { useStats } from "context/providers/stats";
import { useMemo } from "react";
import { getNameByCountryCode } from "utils/countries_list";

const randomNum = () =>
  Math.floor(Math.random() * (235 - 52 + 1) + 52);

const randomRGB = () =>
  Array(3)
    .fill(null)
    .map(() => randomNum());

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
};

const OrdersChart = () => {
  const {
    state: {
      data: { country_codes_chart },
    },
  } = useStats();

  const colors = useMemo(
    () =>
      Array(country_codes_chart.length)
        .fill(null)
        .map(() => randomRGB()),
    []
  );

  const data = {
    labels: country_codes_chart.map(
      ({ name }) => `${getNameByCountryCode(name)}`
    ),
    datasets: [
      {
        label: "Ilość zamówień",
        data: country_codes_chart.map(({ count }) => count),
        borderColor: colors.map(
          ([r, g, b]) => `rgba(${r},${g},${b},1)`
        ),
        backgroundColor: colors.map(
          ([r, g, b]) => `rgba(${r},${g},${b},0.7)`
        ),
      },
    ],
  };

  return <Pie options={options} data={data} />;
};

export default OrdersChart;
