import classNames from "classnames";
import { Dashboard } from "components";
import moment from "moment";
import { wms_url } from "utils/api";
import getWaybillStatus from "utils/getWaybillStatus";

const getPackageDimensions = ({
  width,
  height,
  depth,
  weight,
  gauge_weight,
}) => {
  return `(${width}x${height}x${depth}cm) w: ${weight} kg | wg.: ${
    gauge_weight || "-"
  } kg`;
};

const WaybillsList = ({ data }) => (
  <Dashboard header={{ title: "Przesyłki" }} className="m-t-5">
    <ul className="dashboard__list">
      {data.map(
        ({
          id,
          courier_name,
          waybill_path,
          package_numbers,
          sub_packages,
          tracking_url,
          created_at,
          oms_status,
          status,
        }) => (
          <li key={id} className="df ai-c jc-sb">
            <div>
              <p>{courier_name}</p>
              <span
                className={classNames("label label-sm m-b-5", {
                  "label--info": oms_status === "created",
                  "label--purple": oms_status === "dispatched",
                  "label--danger": oms_status === "errored",
                  "label--warning": oms_status === "returned",
                  "label--success": oms_status === "delivered",
                })}
              >
                {getWaybillStatus(oms_status)} ({status})
              </span>
              {sub_packages.map((sub_package) => (
                <p className="text-muted">
                  {getPackageDimensions(sub_package)}
                </p>
              ))}
              <p className="text-muted">
                Data nadania:{" "}
                {created_at
                  ? moment(created_at).format("DD.MM.YYYY HH:mm")
                  : "-"}
              </p>
              <p className="text-muted">
                Data aktualizacji statusu:{" "}
                {created_at
                  ? moment(created_at).format("DD.MM.YYYY HH:mm")
                  : "-"}
              </p>
              {package_numbers.split(",").map((package_number) => (
                <p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${tracking_url}${package_number}`}
                    className="text-link"
                  >
                    {package_number}
                  </a>
                </p>
              ))}
            </div>
            <a
              href={`${wms_url}/${waybill_path}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-link"
            >
              Pobierz
            </a>
          </li>
        )
      )}
    </ul>
  </Dashboard>
);

export default WaybillsList;
