import "./styles.scss";

import { Watch } from "react-loader-spinner";

const Loader = ({ size = 40}) => (
  <div className="loader">
    <Watch
      height={size}
      width={size}
      color="#ffbf00"
      visible={true}
      secondaryColor="#ffbf00"
      strokeWidth={2}
      strokeWidthSecondary={2}
    />
  </div>
);

export default Loader;
