import classNames from "classnames";
import { useEffect, useRef, useState } from "react";

const BtnMore = ({ actions }) => {
  const ref = useRef();
  const [is_open, setOpen] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        is_open &&
        ref.current &&
        !ref.current.contains(event.target)
      ) {
        setOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener(
      "mousedown",
      handleClickOutside
    );
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener(
        "mousedown",
        handleClickOutside
      );
    };
  }, [is_open, ref]);

  if (!actions) {
    return null;
  }

  return (
    <div className="btn-more-group" ref={ref}>
      <button
        className="btn btn-sm btn-blue"
        type="button"
        onClick={() => setOpen(!is_open)}>
        <svg
          height="24"
          viewBox="0 0 24 24"
          width="24"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5 12H5.01M12 12H12.01M19 12H19.01M6 12C6 12.5523 5.55228 13 5 13C4.44772 13 4 12.5523 4 12C4 11.4477 4.44772 11 5 11C5.55228 11 6 11.4477 6 12ZM13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12ZM20 12C20 12.5523 19.5523 13 19 13C18.4477 13 18 12.5523 18 12C18 11.4477 18.4477 11 19 11C19.5523 11 20 11.4477 20 12Z"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </svg>
      </button>
      <div
        className={classNames("btn-more-panel", {
          open: is_open,
        })}>
        <ul>
          {actions.map(({ name, action }, index) => (
            <li key={index}>
              <button
                type="button"
                onClick={() => {
                  setOpen(false);
                  action();
                }}>
                {name}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default BtnMore;
