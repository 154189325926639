import Modal from "components/Modal";

const WarningIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20">
    <path
      fill="#f4b04f"
      opacity="0.3"
      d="M9.756,4.414,2.8,16.926a.833.833,0,0,0,.728,1.238h14.6a.833.833,0,0,0,.711-1.268L11.2,4.384a.833.833,0,0,0-1.439.03Z"
      transform="translate(-0.45 -0.664)"
    />
    <rect
      fill="#f4b04f"
      width="1.667"
      height="5.833"
      rx="0.833"
      transform="translate(9.167 7.5)"
    />
    <rect
      fill="#f4b04f"
      width="1.667"
      height="1.667"
      rx="0.833"
      transform="translate(9.167 14.167)"
    />
  </svg>
);

const ModalWarning = ({
  handleClose,
  is_loading,
  handleConfirm,
  content,
}) => {
  return (
    <Modal
      header={{
        title: "Uwaga!!",
        icon: <WarningIcon />,
      }}
      handleClose={handleClose}
      footer={
        <>
          <button
            className="btn btn-sm btn-default"
            type="button"
            onClick={handleClose}>
            Nie
          </button>
          <button
            className="btn btn-sm btn-green"
            disabled={is_loading}
            type="button"
            onClick={handleConfirm}>
            {is_loading ? "Proszę czekać..." : "Tak"}
          </button>
        </>
      }>
      <p>{content}</p>
    </Modal>
  );
};

export default ModalWarning;
