import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import qs from "query-string";
import { api } from "utils/api";

const useGet = ({
  path,
  query,
  context,
  onSuccess,
  search = {},
  options = {},
}) => {
  const query_path = qs.stringify(
    { context, ...search },
    { arrayFormat: "bracket" }
  );
  const {
    isLoading: is_loading,
    isFetching: is_fetching,
    isError: is_error,
    data,
    refetch,
  } = useQuery({
    queryKey: query,
    queryFn: () => api.get(`${path}?${query_path}`),
    retry: false,
    keepPreviousData: false,
    select: (res) => ({
      data: res?.data,
      meta: res?.meta,
    }),
    onSuccess: onSuccess,
    onError: (err) =>
      toast(err?.response?.data?.message || "Wystąpił błąd", {
        type: "error",
      }),
    ...options,
  });
  return {
    is_loading,
    is_fetching,
    is_error,
    data: data?.data,
    meta: data?.meta,
    refetch,
  };
};

export default useGet;
