import { Tag } from "components";
import useUpdate from "hooks/useUpdate";
import { useState } from "react";
import { Tooltip } from "react-tooltip";
import renderPrice from "utils/renderPrice";

const getTagType = ({
  already_paid,
  is_cod,
  total_price,
}) => {
  if (is_cod) return null;
  if (already_paid === total_price) {
    return "success";
  }
  return "danger";
};

const PaymentStatus = ({
  order_id,
  already_paid,
  is_cod,
  total_price,
  currency_code,
  refetch,
}) => {
  const [is_editing, setEditing] = useState(false);
  const [price, setPrice] = useState(already_paid);

  const tag_type = getTagType({
    already_paid,
    is_cod,
    total_price,
  });

  const { is_saving, update } = useUpdate({
    path: `/orders/${order_id}`,
    onSuccess: async () => {
      await refetch();
      setEditing(false);
    },
  });

  return (
    <div className="payment-status">
      <p className="payment-status__label">Zapłacono:</p>
      <p className="payment-status__info">
        {is_editing ? (
          <input
            value={price}
            type="number"
            onChange={({ target: { value } }) =>
              setPrice(value)
            }
          />
        ) : (
          <Tag
            type={tag_type}
            tooltip_id="payment_status"
            tooltip_text={
              is_cod ? "Płatność za pobraniem" : null
            }
            onClick={() => setEditing(true)}
          >
            {renderPrice(already_paid, currency_code)}
          </Tag>
        )}
        <span>
          z{" "}
          <strong>
            {renderPrice(total_price, currency_code)}
          </strong>
        </span>
        {is_editing && (
          <div className="price-buttons">
            <button
              className="btn btn-sm btn-green"
              disabled={
                parseFloat(price) < 0 ||
                parseFloat(price) ===
                  parseFloat(already_paid)
              }
              onClick={() =>
                update({ already_paid: price })
              }
            >
              {is_saving ? "Proszę czekać..." : "Zapisz"}
            </button>

            <button
              className="btn btn-sm btn-blue"
              data-tooltip-id="payment_total"
              onClick={() => setPrice(total_price)}
            >
              {total_price}
            </button>
            <button
              className="btn btn-sm btn-blue"
              data-tooltip-id="payment_empty"
              onClick={() => setPrice(0.0)}
            >
              0.00
            </button>
            <button
              className="btn btn-default"
              onClick={() => setEditing(false)}
            >
              Anuluj
            </button>
            <Tooltip
              id="payment_total"
              place="top"
              content="Ustaw jako opłacone"
            />
            <Tooltip
              id="payment_empty"
              place="top"
              content="Ustaw jako nieopłacone"
            />
          </div>
        )}
      </p>
    </div>
  );
};

export default PaymentStatus;
