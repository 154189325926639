import moment from "moment";

export const sort = [
  {
    label: "Numer",
    name: "_id",
    sortable: false,
    style: { width: 100 },
  },
  {
    label: "Imię i nazwisko",
    name: "delivery_fullname",
    extra: "(pochodzenie)",
    sortable: false,
    style: { width: 180 },
  },
  {
    label: "Przedmioty",
    name: "products",
    sortable: false,
  },
  {
    label: "Informacje",
    name: "info",
    sortable: false,
    style: { width: 130 },
  },
  {
    label: "Kwota",
    name: "cost",
    sortable: false,
    style: { width: 100 },
  },
  {
    label: "Informacje dodatkowe",
    name: "additional_info",
    extra: "(sposób wysyłki)",
    sortable: false,
    style: { width: 180 },
  },
  {
    label: "",
    name: "statuses",
    sortable: false,
    style: { width: 100 },
  },
  {
    label: "Data założenia",
    extra: "(w statusie)",
    name: "dates",
    sortable: false,
    style: { width: 130 },
  },
];

export const table_filters = [];

export const main_filters = [
  {
    type: "input",
    label: "Imię i nazwisko",
    name: "delivery_fullname",
    search_type: "matches",
  },
  {
    type: "input",
    label: "Login",
    name: "client_login",
    search_type: "matches",
  },
  {
    type: "input",
    label: "E-mail",
    name: "client_email",
    search_type: "matches",
  },
  {
    type: "input",
    label: "Telefon",
    name: "client_phone",
    search_type: "matches",
  },
  {
    type: "input",
    label: "Kod pocztowy",
    name: "delivery_zipcode",
    search_type: "matches",
  },
  {
    type: "input",
    label: "Miasto",
    name: "delivery_city",
    search_type: "matches",
  },
  {
    type: "date",
    label: "Data zamówienia",
    name: "ordered_at",
    search_type: "range",
  },
  {
    type: "multi-select",
    label: "Metoda dostawy",
    name: "delivery_method",
    search_type: "in",
    options: [],
  },
  {
    type: "multi-select",
    label: "Źródło zamówienia",
    name: "channel_id",
    search_type: "in",
    options: [],
  },
  {
    type: "input",
    label: "Numer listu",
    name: "package_numbers",
    search_type: "matches",
  },
  {
    type: "input",
    label: "SKU produktu",
    name: "product_sku",
    search_type: "matches",
  },
  {
    type: "select",
    label: "Priorytetowa rezerwacja",
    name: "high_priority_reservation",
    search_type: "matches",
    options: [
      { label: "Tak", value: 'true' },
      { label: "Nie", value: 'false' },
    ],
  },
  {
    type: "select",
    label: "Pobranie",
    name: "is_cod",
    search_type: "matches",
    options: [
      { label: "Tak", value: 'true' },
      { label: "Nie", value: 'false' },
    ],
  },
  {
    type: "select",
    label: "Szybka wysyłka",
    name: "is_fast_shipment",
    search_type: "matches",
    options: [
      { label: "Tak", value: 'true' },
      { label: "Nie", value: 'false' },
    ],
  },
  {
    type: "select",
    label: "Status wysyłki",
    name: "dispatch_status",
    search_type: "matches",
    options: [
      { label: "Nowe", value: 'new' },
      { label: "Nadane", value: 'pending' },
      { label: "Odebrane", value: 'dispatched' },
      { label: "Problem", value: 'errored' },
    ],
  },
];

export const createOrderedAtData = (data) => {
  const char_obj = {
    "<5": 0,
    "-4": 0,
    "-3": 0,
    "-2": 0,
    "-1": 0,
    "-0": 0,
  };

  const today = moment();

  data.forEach(({ name, count }) => {
    const diff = today.diff(moment(name), "days");
    switch (diff) {
      case 0:
        char_obj["-0"] = count;
        break;
      case 1:
        char_obj["-1"] = count;
        break;
      case 2:
        char_obj["-2"] = count;
        break;
      case 3:
        char_obj["-3"] = count;
        break;
      case 4:
        char_obj["-4"] = count;
        break;
      default:
        char_obj["<5"] += count;
        break;
    }
  });

  return [
    ["Opóźnienie", "Ilość"],
    ...Object.keys(char_obj).map((key) => [
      key === "-0"
        ? "dziś"
        : key === "-1"
        ? `${key} dzień`
        : `${key} dni`,
      char_obj[key],
    ]),
  ];
};
