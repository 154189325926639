import { useMainList } from "components/MainList/context/provider";

import FilterValues from "./components/FilterValues";
import Input from "./components/Input";
import Select from "./components/Select";
import AsyncSelect from "./components/AsyncSelect";
// import Date from "./components/Date";

const renderFilterComponent = ({
  filter,
  setTableFilterValue,
}) => {
  switch (filter?.type) {
    case "input": {
      return (
        <Input
          {...filter}
          handleSubmit={(value) =>
            setTableFilterValue({
              name: filter.name,
              value,
              label: value,
            })
          }
        />
      );
    }
    case "select": {
      return (
        <Select
          {...filter}
          handleSubmit={({ value, label }) =>
            setTableFilterValue({
              name: filter.name,
              value,
              label,
            })
          }
        />
      );
    }
    case "async_select": {
      return (
        <AsyncSelect
          {...filter}
          handleSubmit={({ value, label }) =>
            setTableFilterValue({
              name: filter.name,
              value,
              label,
            })
          }
        />
      );
    }
    // case "date": {
    //   return null;
    //   return (
    //     <Date
    //       {...filter}
    //       handleSubmit={({ value, label }) =>
    //         setTableFilterValue({
    //           name: filter.name,
    //           value,
    //           label,
    //         })
    //       }
    //     />
    //   );
    // }
    default:
      return null;
  }
};

const TableFilters = () => {
  const {
    state: { table_filters },
    setTableFilterValue,
  } = useMainList();
  if (table_filters?.filter(Boolean)?.length < 1) return null;
  return (
    <thead className="table-filters">
      <tr>
        {table_filters.map((filter, index) => (
          <th key={filter?.name || index}>
            {renderFilterComponent({
              filter,
              index,
              setTableFilterValue,
            })}
            {filter?.values?.length > 0 && (
              <FilterValues
                name={filter.name}
                data={filter.values}
              />
            )}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableFilters;
