import { Fragment } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { Tooltip } from "react-tooltip";
import {
  faDollarSign,
  faTruck,
  faFileInvoiceDollar,
} from "@fortawesome/free-solid-svg-icons";

import { ClipboardCopy, Tag } from "components";

import OrderLines from "./components/OrderLines";

import renderPrice from "utils/renderPrice";
import renderDate from "utils/renderDate";
import getWaybillStatus from "utils/getWaybillStatus";

const OrderItem = ({
  id,
  external_id,
  delivery_fullname,
  delivery_country_code,
  client_login,
  order_lines,
  currency_code,
  standarized_at,
  ordered_at,
  status_changed_at,
  payment_method,
  total_price,
  delivery_method,
  status,
  channel,
  is_confirmed,
  is_cod,
  has_invoices,
  is_invoice_needed,
  already_paid,
  note,
  wms_problem_content,
  client_comment,
  order_errors,
  single_picking_mode,
  waybills,
  is_fast_shipment,
  delivery_dispatch_to,
}) => {
  const is_paid = already_paid === total_price;
  return (
    <>
      <td>
        <Link to={`/orders/${id}`} className="order-identifier">
          {id}
        </Link>
        <p className="text-muted">{external_id}</p>
      </td>
      <td>
        <p>
          <span
            className={`country-flag fi fi-${delivery_country_code?.toLowerCase()}`}
          />
          {delivery_fullname}
        </p>
        <p>
          <ClipboardCopy id={id} text={client_login} /> z
          <br />
          {channel?.identifier}
        </p>
      </td>
      <td>
        <OrderLines
          currency_code={currency_code}
          data={order_lines}
          is_standarized={Boolean(standarized_at)}
          errors={order_errors}
        />
      </td>
      <td>
        <p>{payment_method}</p>
      </td>
      <td>
        <p>{renderPrice(total_price, currency_code)}</p>
      </td>
      <td>
        <span
          data-tooltip-id={`status-${id}`}
          className="label label-sm"
          style={{
            backgroundColor: status.color,
          }}
        >
          {status?.short_name}
        </span>
        <Tooltip id={`status-${id}`}>{status.name}</Tooltip>
        <p className="text-info">{delivery_method}</p>
        {note && <p>Uwagi: {note}</p>}
        {client_comment && <p>Komentarz klienta: {client_comment}</p>}
        {wms_problem_content && <p>Problem: {wms_problem_content}</p>}
        {single_picking_mode ? (
          <Tag type="purple">Pojedyńcza kompletacja</Tag>
        ) : null}
        {is_fast_shipment ? (
          <Tag type="info">
            <FontAwesomeIcon icon={faTruck} color="#fff" /> Szybka wysyłka
          </Tag>
        ) : null}
      </td>
      <td>
        {is_confirmed && (
          <span
            data-tooltip-id={`confirmed-${id}`}
            className="label label-sm label--success"
          >
            P
          </span>
        )}
        <span
          data-tooltip-id={`payment-status-${id}`}
          className={classNames("label label-icon", {
            "label--success": is_paid,
            "label--warning": is_cod,
            "label--danger": !is_cod && !is_paid,
          })}
        >
          <FontAwesomeIcon icon={faDollarSign} color="#fff" />
        </span>
        {waybills.length > 0 ? (
          waybills.map(({ id, oms_status, status, package_numbers }) => (
            <Fragment key={id}>
              <span
                data-tooltip-id={`delivery-package-${id}`}
                className={classNames("label label-icon", {
                  "label--info": oms_status === "created",
                  "label--purple": oms_status === "dispatched",
                  "label--danger": oms_status === "errored",
                  "label--warning": oms_status === "returned",
                  "label--success": oms_status === "delivered",
                })}
              >
                <FontAwesomeIcon icon={faTruck} color="#fff" />
              </span>
              <Tooltip id={`delivery-package-${id}`}>
                {package_numbers} - {getWaybillStatus(oms_status)} ({status})
              </Tooltip>
            </Fragment>
          ))
        ) : (
          <span
            data-tooltip-id="delivery-package-empty"
            className="label label-icon"
          >
            <FontAwesomeIcon icon={faTruck} color="#fff" />
          </span>
        )}
        <span
          data-tooltip-id={`invoice-${id}`}
          className={classNames("label label-icon", {
            "label--warning": is_invoice_needed,
            "label--success": has_invoices,
          })}
        >
          <FontAwesomeIcon icon={faFileInvoiceDollar} color="#fff" />
        </span>
        {is_confirmed && <Tooltip id={`confirmed-${id}`}>Potwierdzone</Tooltip>}
        <Tooltip id={`invoice-${id}`}>
          {has_invoices
            ? "Wystawiono FV"
            : is_invoice_needed
            ? "Klient prosi o fakturę (nie wystawiono)"
            : "Brak FV"}
        </Tooltip>
        <Tooltip id={`payment-status-${id}`}>
          {is_paid
            ? "Opłacone"
            : is_cod
            ? "Nieopłacone (pobranie)"
            : "Nieopłacone"}
        </Tooltip>
        <Tooltip id={`delivery-package-empty`}>
          Nie wygenerowano listu przewozowego
        </Tooltip>
      </td>
      <td>
        <p>{renderDate(ordered_at)}</p>
        <p className="text-info">{renderDate(status_changed_at)}</p>
        {delivery_dispatch_to && (
          <p className="text-error">{renderDate(delivery_dispatch_to)}</p>
        )}
      </td>
    </>
  );
};
export default OrderItem;
