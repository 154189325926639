import { useField } from "formik";

const FormikInput = ({
  prefix,
  disabled,
  placeholder,
  postfix,
  type,
  label,
  name,
}) => {
  const [field, meta] = useField({ name, type });
  const is_errored = meta.touched && meta.error;
  return (
    <div
      style={{ display: type === "hidden" ? "none" : null }}
      className={`form-group ${
        is_errored ? "visited" : ""
      } ${is_errored ? "error" : ""} `}>
      <div className="inp-wrapper">
        {label && (
          <label htmlFor={name} className="label-form">
            {label}
          </label>
        )}

        <div className={`inp-group `}>
          {prefix && (
            <span className="form-span prefix">
              {prefix}
            </span>
          )}
          <input
            className={disabled ? "input-disabled" : ""}
            disabled={disabled}
            placeholder={placeholder}
            id={name}
            {...field}
            type={type}
          />
          {postfix && (
            <span className="form-span postfix">
              {postfix}
            </span>
          )}
        </div>
      </div>
      {is_errored && (
        <p className="inp-error">{meta.error}</p>
      )}
    </div>
  );
};

export default FormikInput;
