import classNames from "classnames";

const Tags = ({ label, name, default_value, options, onChange }) => {
  if (!options?.length) {
    return null;
  }
  return (
    <div className="tags-grid">
      <label htmlFor={name} className="label-form">
        {label}
      </label>
      <div className="tags-grid__wrapper">
        {options?.map(({ label, value }) => (
          <button
            type="button"
            className={classNames("btn btn-tag", {
              active: default_value === value,
            })}
            key={value}
            onClick={() => onChange(value)}
          >
            <span>{label}</span>
          </button>
        ))}
      </div>
    </div>
  );
};
export default Tags;
