import ReactSelect from "react-select";

const styles = {
  control: (styles) => ({
    ...styles,
    borderColor: "#D7D7DA",
    minHeight: 14,
    padding: "0 5px",
    borderRadius: 2,
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
  }),
  option: (provided) => ({
    ...provided,
    fontFamily: "Poppins",
    fontSize: "11px",
    padding: 5,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
  }),
  singleValue: (provided) => ({
    ...provided,
    fontFamily: "Poppins",
    fontSize: "11px",
    lineHeight: "14px",
    padding: 0,
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none",
  }),
  placeholder: (provided) => ({
    ...provided,
    fontFamily: "Poppins",
    fontSize: "11px",
    lineHeight: "14px",
    color: "#D7D7DA",
  }),
};

const Select = ({ handleSubmit, options = [] }) => {
  return (
    <ReactSelect
      className="filters-select"
      onChange={({ label, value }) =>
        handleSubmit({ label, value })
      }
      styles={styles}
      options={options}
      value={null}
      placeholder={"Wybierz..."}
    />
  );
};

export default Select;
