import classnames from "classnames";

const Tabs = ({ active_id, data, onClick }) => (
  <ul className="panel-tabs">
    {data?.map(
      ({ name, id,  disabled = false }) => (
        <li
          key={id}
          className={classnames("tab", {
            active: active_id === id,
            disabled,
          })}
          onClick={() => onClick(id)}
        >
          {name}
        </li>
      )
    )}
  </ul>
);

export default Tabs;
