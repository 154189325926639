import { object, string } from "yup";

const invoice_address_schema = object({
  invoice_address: string().when("is_invoice_needed", {
    is: true,
    then: (schema) => schema.required("Pole wymagane"),
  }),
  invoice_city: string().when("is_invoice_needed", {
    is: true,
    then: (schema) => schema.required("Pole wymagane"),
  }),
  invoice_zipcode: string().when("is_invoice_needed", {
    is: true,
    then: (schema) => schema.required("Pole wymagane"),
  }),
  invoice_country_code: string().when("is_invoice_needed", {
    is: true,
    then: (schema) => schema.required("Pole wymagane"),
  }),
});

export default invoice_address_schema;
