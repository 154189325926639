import { Loader } from "components";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as CollapseIcon } from "icons/collapse.svg";
import classNames from "classnames";
import { Tooltip } from "react-tooltip";

const StatusesList = ({ is_loading, status_id, data }) => {
  const [open_ids, setOpenIds] = useState(
    JSON.parse(
      window.localStorage.getItem("orders_statuses")
    ) || []
  );

  const toggleStatus = (id) => {
    if (open_ids.includes(id)) {
      setOpenIds(open_ids.filter((el_id) => el_id !== id));
    } else {
      setOpenIds([...open_ids, id]);
    }
  };

  useEffect(() => {
    window.localStorage.setItem(
      "orders_statuses",
      JSON.stringify(open_ids)
    );
  }, [open_ids]);

  return (
    <div className="statuses-list">
      {is_loading ? (
        <div className="loader">
          <Loader size={30} />
        </div>
      ) : (
        <>
          <Link className="lg" to={{ pathname: "/orders" }}>
            Wszystkie
          </Link>
          <div className="divider" />
          <ul className="status-group__list">
            {data.map(({ id, name, statuses }) => {
              const is_open = open_ids.includes(id);
              return (
                <li
                  key={id}
                  className={classNames("status-group", {
                    open: is_open,
                  })}
                >
                  <button
                    type="button"
                    className="status-group__header"
                    onClick={() => {
                      if (
                        !statuses
                          .map(({ id }) => Number(id))
                          .includes(Number(status_id))
                      ) {
                        toggleStatus(id);
                      }
                    }}
                  >
                    <p>{name}</p>
                    <CollapseIcon />
                  </button>
                  {is_open && statuses.length > 0 && (
                    <ul>
                      {statuses.map((status) => {
                        const is_empty = !status.count;
                        return (
                          <li
                            key={status.id}
                            className={
                              Number(status_id) ===
                              Number(status.id)
                                ? "active"
                                : ""
                            }
                          >
                            <Link
                              title={status.name}
                              to={{
                                pathname: "/orders",
                                search: `status_id=${status.id}`,
                              }}
                            >
                              <span
                                className="pill"
                                style={{
                                  backgroundColor:
                                    status.color,
                                  fontWeight: is_empty
                                    ? "400"
                                    : "500",
                                }}
                              >
                                {is_empty
                                  ? "-"
                                  : status.count > 999
                                  ? "999+"
                                  : status.count}
                              </span>
                              <span className="status-name">
                                {status.name}
                              </span>

                              {status.active_worker && (
                                <>
                                  <span
                                    className="status-badge"
                                    data-tooltip-id={`status-${status.id}`}
                                  >
                                    <div className="status-badge__dot" />
                                  </span>
                                  <Tooltip
                                    id={`status-${status.id}`}
                                  >
                                    {
                                      status.active_worker
                                        .created_at
                                    }
                                  </Tooltip>
                                </>
                              )}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
        </>
      )}
    </div>
  );
};

export default StatusesList;
