import { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "expano-components";
import { faTruck } from "@fortawesome/free-solid-svg-icons";
import MainSearch from "./components/MainSearch";
import CreateOrderModal from "./components/CreateOrderModal";

const PagePanel = ({ data }) => {
  const [is_open_create_modal, setOpenCreateModal] = useState(false);
  return (
    <div className="order-panel">
      <div className="order-panel__info">
        <div className="info-holder">
          {!!data?.icon && (
            <div className="info-holder__icon">{data?.icon}</div>
          )}
          <div className="info-holder__text">
            <p className="title">{data?.name}</p>
            <ul className="breadcrumbs">
              <li>
                <Link to="/">OMS</Link>
              </li>
              {data?.breadcrumbs?.map(({ path, name }) => (
                <li key={name}>
                  {!!path ? <Link to={path}>{name}</Link> : <span>{name}</span>}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="order-panel__search">
        <MainSearch />
      </div>
      <div className="order-panel__action">
        <Link to="/orders/fast-delivery" className="btn btn-sm btn-blue">
          <FontAwesomeIcon icon={faTruck} color="#fff" />
          <span>Szybka wysyłka</span>
        </Link>
        <Button
          type="add"
          text="Dodaj zamówienie"
          onClick={() => setOpenCreateModal(true)}
        />
        {is_open_create_modal && (
          <CreateOrderModal handleClose={() => setOpenCreateModal(false)} />
        )}
      </div>
    </div>
  );
};

export default PagePanel;
