import { useState } from "react";

const Input = ({ handleSubmit }) => {
  const [value, setValue] = useState("");
  return (
    <form
      className="input-filter"
      onSubmit={(e) => {
        e.preventDefault();
        if (value?.length > 0) {
          handleSubmit(value);
          setValue("");
        }
      }}>
      <input
        type="text"
        placeholder="Wpisz ..."
        value={value}
        onChange={({ target }) => setValue(target.value)}
      />
    </form>
  );
};

export default Input;
