import { object, number, string } from "yup";
const two_digits_after_comma = /^\d+(\.\d{0,2})?$/;

const order_line_schema = object({
  product_sku: string().required("Pole wymagane"),
  quantity: number()
    .positive("Musi być większe od 0")
    .required("Pole wymagane"),
  unit_price: number()
    .positive("Musi być większe od 0")
    .required("Pole wymagane")
    .test(
      "is-decimal",
      "Maksymalnie 2 miejsca po przecinku",
      (val) => {
        if (val !== undefined) {
          return two_digits_after_comma.test(val);
        }
        return true;
      }
    ),
});

export default order_line_schema;
