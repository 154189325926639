import { Head } from "./components";
import Dashboard from "components/Dashboard";

const MainTable = ({
  className,
  data,
  head,
  renderRow,
}) => (
  <Dashboard className={className}>
    <table className="main-table">
      <Head data={head} />
      {data?.length > 0 && (
        <tbody className="main-table__body">
          {data.map((item) => renderRow?.(item))}
        </tbody>
      )}
    </table>
  </Dashboard>
);

export default MainTable;
