import {
  createContext,
  useMemo,
  useReducer,
  useContext,
  useEffect,
} from "react";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import { Login } from "expano-components";
import { PageLoader } from "components";

import {
  api,
  createAxiosAuthorization,
  removeAxiosAuthorization,
} from "utils/api";

import reducer, {
  initial_state,
  SET_LOGGED,
  SET_LOADING,
  SET_USER,
} from "../reducers/auth";

const AuthContext = createContext();

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(
    reducer,
    initial_state
  );

  const [{ auth_token }, setCookie, removeCookie] =
    useCookies(["auth_token"]);

  useEffect(() => {
    if (Boolean(auth_token)) {
      checkAuthorization(auth_token);
    } else {
      setLogged(false);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  const setLoading = (is_loading) =>
    dispatch({
      type: SET_LOADING,
      payload: { is_loading },
    });

  const setLogged = (is_logged) =>
    dispatch({ type: SET_LOGGED, payload: { is_logged } });

  const setUser = (user) =>
    dispatch({ type: SET_USER, payload: { user } });

  const checkAuthorization = async (auth_token) => {
    try {
      const { data } = await api.get("/users/my_account", {
        headers: {
          Authorization: `Bearer ${auth_token}`,
        },
      });
      createAxiosAuthorization(auth_token);
      setUser(data);
      setLogged(true);
      setLoading(false);
    } catch (error) {
      console.error(error);
      removeCookie("auth_token");
      toast.error({
        message: error?.message || "Błąd autoryzacji",
      });
      setLogged(false);
      setLoading(false);
    }
  };

  const handleLogin = async ({ email, password }) => {
    try {
      const { auth_token } = await api.post(
        "/users/sign_in",
        {
          email,
          password,
        }
      );

      setCookie("auth_token", auth_token, {
        path: "/",
        maxAge: 24 * 3600,
      });
      checkAuthorization(auth_token);
    } catch (error) {
      throw error;
    }
  };

  const handleLogout = () => {
    removeAxiosAuthorization();
    removeCookie("auth_token");
    setLogged(false);
    setUser(null);
    toast.success({
      message: "Wylogowano pomyślnie",
    });
  };

  const value = useMemo(() => {
    return {
      user: state.user,
      handleLogin,
      handleLogout,
    };
    // eslint-disable-next-line
  }, [state]);

  if (state.is_loading) {
    return <PageLoader />;
  }

  if (!state.is_logged) {
    return <Login app="oms" handleLogin={handleLogin} />;
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}

const useAuth = () => useContext(AuthContext);
export { useAuth };
export default AuthProvider;
