import { api } from "./api";

const getGusData = async (nip) => {
  try {
    const { data } = await api.get(
      `/orders/gus_data?nip=${nip}`
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export default getGusData;
