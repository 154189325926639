import { useEffect, useMemo, useRef, useState } from "react";
import { Field, Form, Formik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import useUpdate from "hooks/useUpdate";
import { Dashboard } from "components";
import FormActions from "../FormActions";

const WmsInfo = ({ refetch, is_fetching, ...data }) => {
  const [editing_field, setEditingField] = useState(null);
  const ref = useRef();

  const { is_saving, update } = useUpdate({
    path: `/orders/${data.id}`,
    onSuccess: async () => {
      await refetch();
      setEditingField(null);
    },
  });

  const items = [
    [
      {
        label: "WMS braki",
        field: "wms_missing_content",
        field_type: "text",
      },
      {
        label: "WMS problemy",
        field: "wms_problem_content",
        field_type: "text",
      },
    ],
  ];

  useEffect(() => {
    if (!!editing_field) {
      setTimeout(() => {
        ref.current.querySelector(`input[name="${editing_field}"]`)?.focus();
      }, 100);
    }
  }, [editing_field]);

  const initial_values = useMemo(
    () =>
      Object.fromEntries(
        items
          .flat()
          .filter(({ field_type }) => field_type !== "none")
          .map(({ field, field_value }) => [
            field,
            field_value || data?.[field],
          ])
      ),
    //eslint-disable-next-line
    [data]
  );

  const onSubmit = (values) => update(values);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initial_values}
        onSubmit={onSubmit}
      >
        <Dashboard
          header={{
            title: "WMS",
            extra: data?.exported_packable_at && (
              <a
                className="btn btn-sm btn-blue"
                href={`https://wms.tradesk.pl/oms_order/${data.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Przejdź do WMS
              </a>
            ),
          }}
          footer={
            editing_field && (
              <FormActions
                is_saving={is_saving}
                is_fetching={is_fetching}
                setEditingField={setEditingField}
              />
            )
          }
        >
          <div className="order-details__info" ref={ref}>
            {editing_field ? (
              <Form>
                {items.map((group, index) => (
                  <table className="info-group" key={index}>
                    <tbody>
                      {group.map(
                        ({ label, field, field_type, field_label }) => (
                          <tr key={field}>
                            <td>{label}:</td>
                            <td>
                              {field_type === "none" ? (
                                field_label
                              ) : (
                                <Field name={field}>
                                  {({ field }) => (
                                    <input
                                      type={field_type}
                                      placeholder={label}
                                      {...field}
                                    />
                                  )}
                                </Field>
                              )}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                ))}
              </Form>
            ) : (
              items.map((group, index) => (
                <table className="info-group" key={index}>
                  <tbody>
                    {group.map(({ label, field, field_label, field_type }) => (
                      <tr key={field}>
                        <td>{label}:</td>
                        <td>
                          {field_type === "none" ? (
                            field_label
                          ) : (
                            <button
                              onClick={() => setEditingField(field)}
                              type="button"
                              className="editable"
                            >
                              <div className="icon">
                                <FontAwesomeIcon icon={faEdit} />
                              </div>
                              {field_label || data?.[field] || "..."}
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ))
            )}
          </div>
        </Dashboard>
      </Formik>
    </>
  );
};

export default WmsInfo;
