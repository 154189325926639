import { Select } from "expano-components";
import { useField } from "formik";

const FormikSelect = ({ name, options, ...rest }) => {
  const [field, meta, { setValue }] = useField({
    name,
    type: "text",
  });
  return (
    <Select
      {...rest}
      options={options}
      defaultValue={options?.find(({ value }) => value === field.value)}
      handleChange={(value) => setValue(value)}
      meta={meta}
    />
  );
};

export default FormikSelect;
