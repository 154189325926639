import { FormikInput, Modal } from "components";
import { Button } from "expano-components";
import { Formik, Form } from "formik";
import useCreate from "hooks/useCreate";
import { useState } from "react";
import { useParams } from "react-router-dom";
import order_line_schema from "utils/validations/order_line_schema";

const CreateOrderLineModal = ({
  currency_code,
  refetch,
}) => {
  const { id: order_id } = useParams();
  const [is_open, setOpen] = useState(false);
  const initial_values = {
    order_id,
    product_sku: "",
    quantity: "",
    unit_price: "",
    comment: "",
  };

  const { is_saving, create } = useCreate({
    path: "/order_lines",
    onSuccess: () => {
      refetch();
      setOpen(false);
    },
  });
  return (
    <div className="df jc-fs">
      {is_open && (
        <Formik
          initialValues={initial_values}
          validationSchema={order_line_schema}
          onSubmit={create}
        >
          {({ submitForm }) => (
            <Modal
              header={{ title: "Dodaj pozycję" }}
              handleClose={() => setOpen(false)}
              footer={
                <>
                  <Button
                    text="Anuluj"
                    onClick={() => setOpen(false)}
                  />
                  <Button
                    disabled={is_saving}
                    type="save"
                    text={
                      is_saving
                        ? "Proszę czekać..."
                        : "Dodaj"
                    }
                    onClick={submitForm}
                  />
                </>
              }
            >
              <Form>
                <FormikInput
                  label="SKU"
                  type="text"
                  name="product_sku"
                />
                <FormikInput
                  label="Ilość"
                  type="number"
                  name="quantity"
                />
                <FormikInput
                  label="Cena"
                  type="number"
                  name="unit_price"
                  postfix={currency_code}
                />
                <FormikInput
                  label="Komentarz"
                  type="text"
                  name="comment"
                />
              </Form>
            </Modal>
          )}
        </Formik>
      )}
      <button
        type="button"
        className="btn btn-sm btn-blue"
        onClick={() => setOpen(true)}
      >
        Dodaj
      </button>
    </div>
  );
};

export default CreateOrderLineModal;
