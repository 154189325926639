import { useEffect, useState } from "react";

let timer;
const Input = ({ label, name, value, onChange }) => {
  const [input_value, setInputValue] = useState("");

  useEffect(() => {
    setInputValue(value || "");
  }, [value]);

  return (
    <div className="inp-wrapper">
      <label htmlFor={name} className="label-form">
        {label}
      </label>
      <div className="inp-group">
        <input
          type="text"
          onChange={({ target }) => {
            clearTimeout(timer);
            setInputValue(target.value);
            timer = setTimeout(() => {
              onChange(target.value);
            }, 400);
          }}
          id={name}
          name={name}
          value={input_value}
        />
      </div>
    </div>
  );
};
export default Input;
