const getWaybillStatus = (status) => {
  switch (status) {
    case "created":
      return "Oczekuje na odbiór";
    case "dispatched":
      return "W doręczeniu";
    case "errored":
      return "Błąd";
    case "returned":
      return "Zwrot";
    case "delivered":
      return "Dostarczone";
    default:
      return "";
  }
};

export default getWaybillStatus