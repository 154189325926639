import ReactSelect from "react-select";

const styles = {
  control: (styles) => ({
    ...styles,
    borderColor: "#E2E5EC",
    borderWidth: 1,
    borderRadius: 4,
    minHeight: 30,
  }),
  option: (provided) => ({
    ...provided,
    fontFamily: "Poppins",
    fontSize: "13px",
  }),
  singleValue: (provided) => ({
    ...provided,
    fontFamily: "Poppins",
    fontSize: "13px",
    padding: 0,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "1px 10px",
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none",
  }),
  placeholder: (provided) => ({
    ...provided,
    fontFamily: "Poppins",
    fontSize: "13px",
  }),
  multiValue: (provided) => ({
    ...provided,
    flexDirection: "row-reverse",
    backgroundColor: "#F3F6F9",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    paddingRight: "0 6px",
    fontSize: 10,
    color: "#595D6E",
  }),
};

const Select = ({
  label,
  options = [],
  value = null,
  onChange,
}) => {
  return (
    <div className="inp-wrapper">
      {label && <label>{label}</label>}
      <ReactSelect
        onChange={onChange}
        isClearable={true}
        styles={styles}
        placeholder={"Wybierz..."}
        options={options || []}
        value={options.find((item) => item.value === value) || null}
      />
    </div>
  );
};

export default Select;
